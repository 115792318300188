import { suffixes } from "../data/suffixes";

export interface AddressComponents {
  houseNumber: string;
  preDirection: string;
  street: string;
  suffix: string;
  postDirection: string;
  unitNumber: string;
  unitType: string;
  city: string;
  state: string;
  zip: string;
}

// Common unit type designators and their variations
const unitTypes = {
  APARTMENT: ["APT", "APARTMENT", "AP"],
  SUITE: ["STE", "SUITE", "SU"],
  UNIT: ["UNIT", "UN"],
  BUILDING: ["BLDG", "BUILDING", "BLD"],
  FLOOR: ["FL", "FLOOR", "FLR"],
  ROOM: ["RM", "ROOM"],
  SPACE: ["SPC", "SPACE"],
  BASEMENT: ["BSMT", "BASEMENT"],
  DEPARTMENT: ["DEPT", "DEPARTMENT"],
  PENTHOUSE: ["PH", "PENTHOUSE"],
  OFFICE: ["OFC", "OFFICE", "OF"],
  LOT: ["LOT", "LT"],
};

const parseUnitType = (
  subpremise: string
): { unitType: string; unitNumber: string } => {
  if (!subpremise) {
    return { unitType: "", unitNumber: "" };
  }

  // Remove any leading '#' or '-' characters
  const cleanSubpremise = subpremise.replace(/^[#-]/, "").trim();

  // Try to match known unit types
  for (const [type, variations] of Object.entries(unitTypes)) {
    for (const variation of variations) {
      // Check for patterns like "APT 123" or "APT-123" or "APT#123"
      const regexWithSpace = new RegExp(`^${variation}[\\s#-]*(\\w+)$`, "i");
      const match = cleanSubpremise.match(regexWithSpace);
      if (match) {
        return {
          unitType: type.charAt(0) + type.slice(1).toLowerCase(),
          unitNumber: match[1],
        };
      }
    }
  }

  // If no unit type is found but we have a number, assume it's a unit
  if (/^\d+$/.test(cleanSubpremise)) {
    return {
      unitType: "Unit",
      unitNumber: cleanSubpremise,
    };
  }

  // If we can't determine the type, return the whole thing as the number
  return {
    unitType: "Unit",
    unitNumber: cleanSubpremise,
  };
};

export const parseAddressComponents = async (
  placeId: string,
  _fullAddress?: string
): Promise<AddressComponents> => {
  const geocoder = new google.maps.Geocoder();
  const geocodeResult = await new Promise<google.maps.GeocoderResult>(
    (resolve, reject) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === "OK" && results?.[0]) {
          resolve(results[0]);
        } else {
          reject(new Error("Geocoding failed"));
        }
      });
    }
  );

  const components = geocodeResult.address_components;
  const mapping: { [key: string]: string } = {};

  // First pass: map all components
  components.forEach((component) => {
    component.types.forEach((type) => {
      mapping[type] = component.short_name;
    });
  });

  let streetNumber = "";

  if (_fullAddress) {
    const fullAddressMatch = _fullAddress.match(/^(\d+)/);
    if (fullAddressMatch) {
      streetNumber = fullAddressMatch[1];
    }
  }

  // If not found in full address, try to extract from the original place_id
  if (!streetNumber) {
    const placeIdParts = placeId.split(",");
    const firstPart = placeIdParts[0];
    const numberMatch = firstPart.match(/(\d{3,})/);
    if (numberMatch) {
      streetNumber = numberMatch[1];
    }
  }

  // If still not found, try to extract from the route
  if (!streetNumber) {
    const routeMatch = mapping["route"]?.match(/^(\d+)/);
    if (routeMatch) {
      streetNumber = routeMatch[1];
    }
  }

  // Last resort: try to extract from the full address string in the geocode result
  if (!streetNumber && geocodeResult.formatted_address) {
    const addressParts = geocodeResult.formatted_address.split(",");
    const firstPart = addressParts[0].trim();
    const numberMatch = firstPart.match(/(\d+)/);
    if (numberMatch) {
      streetNumber = numberMatch[1];
    }
  }

  const streetName = mapping["route"] || "";
  const streetParts = streetName.split(" ");

  const directions = ["N", "S", "E", "W", "NE", "NW", "SE", "SW"];

  let preDirection = "";
  let postDirection = "";
  let suffix = "";
  let street = streetParts.join(" ");

  // Handle pre-direction
  if (directions.includes(streetParts[0])) {
    preDirection = streetParts.shift() || "";
    street = streetParts.join(" ");
  }

  // Handle post-direction and suffix
  if (streetParts.length > 0) {
    for (let i = streetParts.length - 1; i >= 0; i--) {
      const part = streetParts[i];

      // Check for direction at the end
      if (directions.includes(part)) {
        postDirection = streetParts.splice(i, 1)[0];
        continue;
      }

      // Check for suffix - both full and abbreviated forms
      const upperPart = part.toUpperCase();
      if (suffixes.includes(part) || suffixes.includes(upperPart)) {
        suffix = part;
        streetParts.splice(i, 1);
        break;
      }
    }
  }

  // For county roads, set the street name appropriately
  if (streetName.match(/Co\s+Rd/i)) {
    street = streetName.replace(/^(\d+\s+)?/, ""); // Remove any leading numbers
  } else {
    street = streetParts.join(" ");
  }

  // Parse unit information
  const { unitType, unitNumber } = parseUnitType(mapping["subpremise"] || "");

  // Try to get city from locality first, then sublocality, then full address
  let city = mapping["locality"] || mapping["sublocality"] || "";

  // Extract city from full address if not found in components
  if (!city && geocodeResult.formatted_address) {
    const addressParts = geocodeResult.formatted_address.split(",");
    if (addressParts.length > 1) {
      const cityPart = addressParts[1].trim().split(" ")[0];
      if (cityPart && cityPart !== mapping["administrative_area_level_1"]) {
        city = cityPart;
      }
    }
  }

  const result = {
    houseNumber: streetNumber,
    preDirection,
    street,
    suffix,
    postDirection,
    unitNumber,
    unitType,
    city,
    state: mapping["administrative_area_level_1"] || "",
    zip: mapping["postal_code"] || "",
  };

  return result;
};
