import React, { useMemo, useCallback, useState, useEffect } from "react";
import { FixedSizeGrid } from "react-window";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";
import UserNoteCard from "./UserNoteCard";
import NoteCardSkeleton from "./NoteCardSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";

interface UserNoteCardGridProps {
  notes: MortgageOrIncomeNote[];
  status: string;
  fetchSavedNotes?: (showLoading?: boolean) => void;
  searchTerm?: string;
}

// Create a search index for faster filtering
const createSearchIndex = (note: MortgageOrIncomeNote) => {
  const address =
    `${note.street_number} ${note.city}, ${note.state} ${note.postal_code}`.toLowerCase();
  const externalId = (note.external_id || "").toLowerCase();
  return { address, externalId };
};

// Add a custom hook for window size
const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
};

const UserNoteCardGrid = ({
  notes,
  status,
  fetchSavedNotes,
  searchTerm,
}: UserNoteCardGridProps) => {
  const skeletonCount = 3;
  const { width } = useWindowSize();

  // Calculate grid dimensions
  const columnCount = width < 768 ? 1 : width < 1024 ? 2 : 3;
  const rowCount = Math.ceil(notes.length / columnCount);
  const columnWidth = Math.floor((width - 64) / columnCount); // 64px for gaps and padding
  const rowHeight = 400; // Adjust based on your card height

  // Optimize filtering with pre-computed search index
  const filteredNotes = useMemo(() => {
    if (!searchTerm?.trim()) return notes;

    const search = searchTerm.toLowerCase().trim();
    const searchWords = search.split(/\s+/);

    // Pre-compute search indices
    const notesWithIndex = notes.map((note) => ({
      note,
      searchIndex: createSearchIndex(note),
    }));

    // Filter using AND logic for multiple words
    return notesWithIndex
      .filter(({ searchIndex }) =>
        searchWords.every(
          (word) =>
            searchIndex.address.includes(word) ||
            searchIndex.externalId.includes(word)
        )
      )
      .map(({ note }) => note);
  }, [notes, searchTerm]);

  const Cell = useCallback(
    ({ columnIndex, rowIndex, style }: any) => {
      const index = rowIndex * columnCount + columnIndex;
      if (index >= filteredNotes.length) return null;

      const note = filteredNotes[index];
      return (
        <div style={style} className="p-2">
          <UserNoteCard note={note} fetchSavedNotes={fetchSavedNotes} />
        </div>
      );
    },
    [filteredNotes, fetchSavedNotes, columnCount]
  );

  if (status === "loading") {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full pb-12">
        {Array(skeletonCount)
          .fill(null)
          .map((_, index) => (
            <div key={`skeleton-${index}`} className="flex">
              <NoteCardSkeleton />
            </div>
          ))}
      </div>
    );
  }

  if (filteredNotes.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center py-12 mb-12">
        <span className="font-extrabold text-warning">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="mx-4 text-lg"
          />
          {searchTerm
            ? "No notes match your search"
            : "You haven't created any notes yet"}
        </span>
      </div>
    );
  }

  return (
    <div className="w-full pb-12">
      <FixedSizeGrid
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={Math.min(window.innerHeight - 200, rowCount * rowHeight)}
        rowCount={rowCount}
        rowHeight={rowHeight}
        width={width - 64}
      >
        {Cell}
      </FixedSizeGrid>
    </div>
  );
};

export default React.memo(UserNoteCardGrid);
