import React, { useState, useEffect } from "react";
import { userApi } from "../../../api";
import useAuth from "../../../hooks/useAuth";
import useAlert from "../../../hooks/useAlert";
import LoadingSpinner from "../../loading/LoadingSpinner";
import useModal from "../../../hooks/useModal";

interface LoginWithEmailProps {
  goToNextSlide: () => void;
  mode?: "login" | "reset";
}

const LoginWithEmail: React.FC<LoginWithEmailProps> = ({
  goToNextSlide,
  mode = "login",
}) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");

  const { loginWithEmail } = useAuth();
  const { setAlert } = useAlert();
  const { setShowModal } = useModal();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCountdownActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCountdownActive(false);
    }
    return () => clearInterval(timer);
  }, [countdown, isCountdownActive]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleRequestOtp = async () => {
    if (!validateEmail(email)) return;

    setIsLoading(true);
    try {
      if (mode === "login") {
        await userApi.createOneTimePassword(email);
      } else {
        await userApi.recoverPassword(email);
      }
      setIsOtpSent(true);
      setCountdown(120);
      setIsCountdownActive(true);
      setAlert({
        display: true,
        message:
          mode === "login"
            ? "One-time password has been sent to your email"
            : "Password reset instructions have been sent to your email",
        type: "info",
      });
    } catch (error) {
      setAlert({
        display: true,
        message:
          mode === "login"
            ? "Failed to send one-time password"
            : "Failed to send password reset instructions",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      if (mode === "reset") {
        setShowModal(false);
      }
    }
  };

  const handleLogin = async () => {
    if (!otp) {
      setOtpError("Please enter the one-time password");
      return;
    }

    setIsLoading(true);
    try {
      const token = await loginWithEmail(email, otp);
      if (token) {
        setAlert({
          display: true,
          message: "Login successful",
          type: "info",
        });
      }
    } catch (error) {
      setOtpError("Invalid one-time password");
      setAlert({
        display: true,
        message: "Login failed",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center justify-center h-full gap-2 w-full xs:px-4 sm:px-12 md:px-28">
      <div className="w-full max-w-96">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isOtpSent && mode === "login"}
          className={`input input-bordered ${
            emailError ? "border-red-500" : "border-light-brown"
          } focus:border-light-brown placeholder:text-light-brown bg-white text-brown w-full`}
        />
        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
      </div>

      {!isOtpSent || mode === "reset" ? (
        <div className="w-full max-w-96">
          <button
            onClick={handleRequestOtp}
            disabled={isLoading}
            className="btn btn-accent w-full text-brown"
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : mode === "login" ? (
              "GET ONE-TIME PASSWORD"
            ) : (
              "RESET PASSWORD"
            )}
          </button>
        </div>
      ) : (
        <>
          <div className="w-full max-w-96">
            <input
              type="text"
              placeholder="Enter 6-digit code"
              value={otp}
              onChange={(e) =>
                setOtp(e.target.value.replace(/\D/g, "").slice(0, 6))
              }
              className={`input input-bordered ${
                otpError ? "border-red-500" : "border-light-brown"
              } focus:border-light-brown placeholder:text-light-brown bg-white text-brown w-full`}
              maxLength={6}
            />
            {otpError && <p className="text-red-500 text-sm">{otpError}</p>}
          </div>

          <div className="w-full max-w-96 flex flex-col gap-2">
            <button
              onClick={handleLogin}
              disabled={isLoading || otp.length !== 6}
              className="btn btn-accent w-full text-brown"
            >
              {isLoading ? <LoadingSpinner /> : "LOGIN"}
            </button>

            {countdown > 0 ? (
              <p className="text-main-gray text-center">
                Resend code in {formatTime(countdown)}
              </p>
            ) : (
              <button
                onClick={handleRequestOtp}
                disabled={isLoading}
                className="text-main-gray underline cursor-pointer font-semibold text-center"
              >
                Resend Code
              </button>
            )}
          </div>
        </>
      )}

      <div className="my-2 mt-6">
        <p className="text-main-gray">
          Login with{" "}
          <span
            className="text-main-gray underline cursor-pointer font-semibold"
            onClick={goToNextSlide}
          >
            Password
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginWithEmail;
