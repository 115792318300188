import React, { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import useAlert from "../hooks/useAlert";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  message?: string;
}

const Login = ({ message }: LoginProps) => {
  const { login } = useAuth();

  const { setAlert } = useAlert();

  const navigate = useNavigate();

  const [loginData, setLoginData] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (message) {
      setAlert({
        type: "info",
        display: true,
        message: message,
      });
    }
  }, [message]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [loginErrors, setLoginErrors] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const validateLogin = () => {
    const newErrors: { email: string; password: string } = {
      email: "",
      password: "",
    };
    if (!loginData.email) {
      newErrors.email = "Email is required";
    }
    if (!loginData.password) {
      newErrors.password = "Password is required";
    }
    setLoginErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    return !hasErrors;
  };

  const submitLogin = async () => {
    if (!validateLogin()) {
      console.log("Invalid login data");
      return;
    }
    try {
      const token: any = await login(
        loginData.email.trim().toLowerCase(),
        loginData.password
      );
      if (token && token.length > 0) {
        navigate("/");
        setAlert({
          display: true,
          message: "You've been logged in successfully",
          type: "info",
        });
      } else {
        setLoginErrors((prev) => ({
          ...prev,
          password: "Invalid email or password",
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full gap-2 w-full xs:px-4 sm:px-12 md:px-28">
        <div className="w-full max-w-96">
          <input
            type="text"
            name="email"
            placeholder="Email"
            className={`input input-bordered ${
              loginErrors.email ? "border-red-500" : "border-light-brown"
            } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
            value={loginData.email}
            onChange={handleChange}
          />
          {loginErrors.email && (
            <p className="text-red-500 text-sm">{loginErrors.email}</p>
          )}
        </div>
        <div className="w-full  max-w-96">
          <input
            type="password"
            name="password"
            placeholder="Password"
            className={`input input-bordered ${
              loginErrors.password ? "border-red-500" : "border-light-brown"
            } focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full`}
            value={loginData.password}
            onChange={handleChange}
          />
          {loginErrors.password && (
            <p className="text-red-500 text-sm">{loginErrors.password}</p>
          )}
        </div>
        <div className="w-full  max-w-96">
          <button
            className="btn btn-accent w-full text-brown"
            onClick={submitLogin}
          >
            {isLoading ? <LoadingSpinner /> : "LOGIN"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
