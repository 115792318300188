import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import {
  fetchMortgageNotes,
  updateFiltersAndFetch,
  deserializeDates,
} from "../redux/slices/mortgageNotesSlice";
import NoteCardGrid from "../components/notes/NoteCardGrid";
import FilterToolbar from "../components/notes/FilterToolbar";
import NotesHeader from "../components/notes/NotesHeader";
import useAuth from "../hooks/useAuth";
import FilterDrawer from "../components/notes/FilterDrawer";
import { FilterState } from "../components/notes/FilterForm";
import { urlParamsToFilters, processFilters } from "../utils/filterUtils";
import {
  FetchNotesParams,
  SerializableFetchNotesParams,
} from "../redux/interfaces/mortgageNote.redux.interface";
import ActiveFilters from "../components/notes/ActiveFilters";
import { NoteStatus } from "../interfaces/mortgageNote.interface";

const Notes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, isLoading } = useAuth();

  const { notes, status, hasMore, filters } = useSelector(
    (state: RootState) => state.mortgageNotes
  );

  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);

  const loadMoreNotes = useCallback(() => {
    if (status === "idle" && hasMore) {
      dispatch(fetchMortgageNotes({ ...filters, offset: notes.length }));
    }
  }, [dispatch, filters, hasMore, notes.length, status]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreNotes();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreNotes]);

  useEffect(() => {
    if (isLoading) return;

    const searchParams = new URLSearchParams(location.search);
    const parsedFilters = urlParamsToFilters(searchParams);

    const fetchParams: Partial<FetchNotesParams> = {
      ...parsedFilters,
      status: ["Available"],
      offset: 0,
      limit: 10,
      user_id: currentUser?.id,
    };

    dispatch(
      updateFiltersAndFetch({
        filters: fetchParams,
        navigate,
      })
    );
  }, [dispatch, location.search, navigate, isLoading, currentUser?.id]);

  const handleApplyFilters = (newFilters: FilterState) => {
    const mappedFilters = processFilters(newFilters, navigate);

    const fetchParams: Partial<FetchNotesParams> = {
      ...mappedFilters,
      status: ["Available"],
      offset: 0,
      limit: 10,
      user_id: currentUser?.id,
    };

    dispatch(
      updateFiltersAndFetch({
        filters: fetchParams,
        navigate,
      })
    );
  };

  const handleRemoveFilter = (
    field: keyof FilterState,
    value?: string | number
  ) => {
    // Create a copy of current filters
    const currentFilters = { ...filters };

    if (value !== undefined && Array.isArray(currentFilters[field])) {
      // Remove single value from array filter
      const arrayField = field as keyof typeof currentFilters &
        keyof SerializableFetchNotesParams;
      const newArray = (currentFilters[arrayField] as string[]).filter(
        (v) => v.toString() !== value.toString()
      );
      (currentFilters[arrayField] as any[]) = newArray;
    } else if (field === "originationDate" || field === "maturityDate") {
      // Reset date range
      const dateField = field as "originationDate" | "maturityDate";
      currentFilters[dateField] = { start: null, end: null };
    } else {
      // Reset numeric range
      const rangeField = field as keyof typeof currentFilters;
      if (
        typeof currentFilters[rangeField] === "object" &&
        currentFilters[rangeField] !== null
      ) {
        const numericField = rangeField as keyof SerializableFetchNotesParams;
        (currentFilters[numericField] as any) = { min: null, max: null };
      }
    }

    const deserializedFilters = deserializeDates(currentFilters);
    const fetchParams: Partial<FetchNotesParams> = {
      ...deserializedFilters,
      status: ["Available" as NoteStatus],
      offset: 0,
      limit: 10,
      user_id: currentUser?.id,
    };

    dispatch(
      updateFiltersAndFetch({
        filters: fetchParams,
        navigate,
      })
    );
  };


  return (
    <>
      <div className="w-full px-4">
        <div className="sm:px-7 md:px-5">
          <NotesHeader />
          <div className="my-5"></div>
          <FilterToolbar />
          <div className="flex flex-row flex-wrap gap-2 mb-6">
            <ActiveFilters
              onRemoveFilter={handleRemoveFilter}
              setShowFilterDrawer={setShowFilterDrawer}
            />
          </div>
        </div>
        <div className="w-full flex justify-center">
          <NoteCardGrid notes={notes} status={status} />
        </div>
        <FilterDrawer
          showFilterDrawer={showFilterDrawer}
          setShowFilterDrawer={setShowFilterDrawer}
          onApplyFilters={handleApplyFilters}
        />
      </div>
    </>
  );
};

export default Notes;
