import React from "react";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";
import { FullMortgageNote } from "../../../interfaces/mortgageNote.interface";
import IncomeNoteDetails from "./IncomeNoteDetails";
import MortgageNoteDetails from "./MortgageNoteDetails";

interface SellerDetailsModalProps {
  note: FullMortgageNote | FullIncomeNote;
  fetchNote?: () => Promise<void>;
}

const SellerDetailsModal = ({ note, fetchNote }: SellerDetailsModalProps) => {
  return (
    <>
      {"loan_number" in note && <MortgageNoteDetails note={note} />}
      {!("loan_number" in note) && (
        <IncomeNoteDetails note={note} fetchNote={fetchNote} />
      )}
    </>
  );
};

export default SellerDetailsModal;
