import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/pro-solid-svg-icons";
import LoadingSpinner from "../../loading/LoadingSpinner";
import OfferInput from "./OfferInput";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";

interface OfferSlideProps {
  note: FullIncomeNote;
  goToPrevSlide: () => void;
  goToNextSlide: () => void;
  isSubmitting: boolean;
  offerAmount: string;
  setOfferAmount: (value: string) => void;
  onSubmit: () => Promise<void>;
}

const OfferSlide = ({
  note,
  goToPrevSlide,
  offerAmount,
  setOfferAmount,
  isSubmitting,
  onSubmit,
}: OfferSlideProps) => {
  return (
    <div className="w-full flex flex-col px-8 pt-20 lg:pt-4">
      <div className="flex items-center justify-center mb-1">
        <button onClick={goToPrevSlide} className="btn btn-ghost btn-sm">
          ← Back
        </button>
        <h2 className="text-2xl font-bold text-gray-800 ml-4">
          Submit Your Offer
        </h2>
      </div>

      <div className="flex flex-col gap-6 items-center justify-center mt-8">
        <div className="w-full max-w-md">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Your Offer Amount</span>
            </label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="text-gray-500"
                />
              </span>
              <OfferInput
                offerAmount={offerAmount}
                setOfferAmount={setOfferAmount}
              />
            </div>
          </div>
          <button
            onClick={onSubmit}
            disabled={!offerAmount || isSubmitting}
            className="btn btn-accent w-full mt-4"
          >
            {isSubmitting ? <LoadingSpinner /> : "Submit Offer"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferSlide;
