import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { DocumentType } from "../../interfaces/incomeNote.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

export interface DocumentItem {
  id: string;
  file: File;
  type: DocumentType;
  preview?: string;
  existing?: boolean;
}

interface DocumentUploadProps {
  documents: DocumentItem[];
  onChange: (documents: DocumentItem[]) => void;
}

export const DocumentUpload = ({
  documents,
  onChange,
}: DocumentUploadProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (documents.length + files.length > 3) {
      alert("Maximum 3 documents allowed");
      return;
    }

    const newDocs: DocumentItem[] = files.map((file) => ({
      id: Math.random().toString(36),
      file,
      type: "Other",
    }));

    onChange([...documents, ...newDocs]);
  };

  const handleTypeChange = (id: string, type: DocumentType) => {
    onChange(documents.map((doc) => (doc.id === id ? { ...doc, type } : doc)));
  };

  const handleRemove = (id: string) => {
    onChange(documents.filter((doc) => doc.id !== id));
  };

  return (
    <div className="space-y-4">
      <input
        type="file"
        accept=".pdf,.doc,.docx"
        multiple
        onChange={handleFileChange}
        disabled={documents.length >= 3}
        className="file-input file-input-bordered file-input-accent file-input-sm w-full"
      />

      <AnimatePresence>
        {documents.map((doc) => (
          <motion.div
            key={doc.id}
            layout
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center gap-4 p-2 border rounded-lg"
          >
            <div className="">
              <span className="text-sm truncate flex-1 ml-1.5">
                {doc.file.name}
              </span>
              <select
                value={doc.type}
                onChange={(e) =>
                  handleTypeChange(doc.id, e.target.value as DocumentType)
                }
                className="select select-sm mt-1"
              >
                <option value="Promissory Note">Promissory Note</option>
                <option value="Deed of Trust or Mortgage or Land Contract">
                  Deed of Trust/Mortgage/Land Contract
                </option>
                <option value="Other">Other</option>
              </select>
            </div>
            <button
              onClick={() => handleRemove(doc.id)}
              className="btn btn-sm btn-error"
            >
              <FontAwesomeIcon icon={faTrashCan} className="text-white" />
            </button>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};
