import React, { useState } from "react";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";
import { Swiper, SwiperSlide } from "swiper/react";
import useSwiper from "../../../hooks/useSwiper";
import "swiper/css";
import MainDetailsSlide from "./MainDetailsSlide";
import OfferSlide from "./OfferSlide";
import UploadDocumentsSlide from "./UploadDocumentsSlide";
import ConfirmationSlide from "./ConfirmationSlide";
import { incomeNoteApi } from "../../../api";
import useAuth from "../../../hooks/useAuth";

interface SellerDetailsModalProps {
  note: FullIncomeNote;
  fetchNote?: () => Promise<void>;
}

interface UploadedFile {
  file: File;
  name: string;
}

const SellerDetailsModal = ({ note, fetchNote }: SellerDetailsModalProps) => {
  const { onSwiper, goToNextSlide, goToPrevSlide, onSlideChange } = useSwiper({
    initialSlide: 0,
  });
  const { currentUser } = useAuth();

  // Shared state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [offerAmount, setOfferAmount] = useState<string>("");
  const [loiFile, setLoiFile] = useState<UploadedFile | null>(null);
  const [pofFile, setPofFile] = useState<UploadedFile | null>(null);

  const handleSubmit = async () => {
    if (!currentUser) return;
    setIsSubmitting(true);

    try {
      // Case 1: Only accept_docs is true - submit from docs page
      if (note.accept_docs && !note.accept_offers) {
        if (!loiFile || !pofFile) return;
        const formData = new FormData();
        formData.append("loi_file", loiFile.file);
        formData.append("pof_file", pofFile.file);

        await incomeNoteApi.toggleSavedNote({
          user_id: currentUser.id,
          income_note_id: note.id,
          is_interested: true,
          formData: formData,
        });
        goToNextSlide();
        fetchNote?.();
        return;
      }
      // Case 2: Only accept_offers is true - submit from offers page
      // Case 3: Both are true - submit from offers page with both docs and offer
      else if (note.accept_offers) {
        if (!offerAmount) return;
        const payload: any = {
          user_id: currentUser.id,
          income_note_id: note.id,
          is_interested: true,
          offer_amount: parseFloat(offerAmount.replace(/[^\d.]/g, "")),
        };

        // If both are true, include the documents
        if (note.accept_docs) {
          if (!loiFile || !pofFile) return;
          const formData = new FormData();
          formData.append("loi_file", loiFile.file);
          formData.append("pof_file", pofFile.file);
          payload.formData = formData;
        }

        await incomeNoteApi.toggleSavedNote(payload);
        fetchNote?.();
      }
      goToNextSlide();
    } catch (error) {
      console.error("Error submitting:", error);
    } finally {
      fetchNote?.();
      setIsSubmitting(false);
    }
  };

  // Helper to determine if we should show the docs slide
  const shouldShowDocsSlide = note.accept_docs && !note.accept_offers;

  const handleDocsNext = async () => {
    goToNextSlide();
    return Promise.resolve();
  };

  return (
    <div className="w-full">
      {!note.accept_docs && !note.accept_offers && (
        <MainDetailsSlide note={note} />
      )}
      {(note.accept_docs || note.accept_offers) && (
        <Swiper
          onSwiper={onSwiper}
          onSlideChange={onSlideChange}
          allowTouchMove={false}
          spaceBetween={40}
        >
          <SwiperSlide>
            <MainDetailsSlide note={note} goToNextSlide={goToNextSlide} />
          </SwiperSlide>
          {note.accept_docs ? (
            <SwiperSlide>
              <UploadDocumentsSlide
                note={note}
                goToPrevSlide={goToPrevSlide}
                goToNextSlide={goToNextSlide}
                isSubmitting={isSubmitting}
                loiFile={loiFile}
                pofFile={pofFile}
                setLoiFile={setLoiFile}
                setPofFile={setPofFile}
                onSubmit={shouldShowDocsSlide ? handleSubmit : handleDocsNext}
              />
            </SwiperSlide>
          ) : null}
          {note.accept_offers ? (
            <SwiperSlide>
              <OfferSlide
                note={note}
                goToPrevSlide={goToPrevSlide}
                goToNextSlide={goToNextSlide}
                isSubmitting={isSubmitting}
                offerAmount={offerAmount}
                setOfferAmount={setOfferAmount}
                onSubmit={handleSubmit}
              />
            </SwiperSlide>
          ) : null}
          <SwiperSlide>
            <ConfirmationSlide note={note} />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
};

export default SellerDetailsModal;
