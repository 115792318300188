import React, { useState } from "react";
import { FullIncomeNote } from "../../interfaces/incomeNote.interface";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import useAlert from "../../hooks/useAlert";
import useModal from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

interface DeleteNoteButtonProps {
  incomeNote: FullIncomeNote;
}

const DeleteNoteButton = ({ incomeNote }: DeleteNoteButtonProps) => {
  const { currentUser } = useAuth();

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleClick = async () => {
    openModalWith({
      title: "Delete Note",
      body: (
        <div className="text-error font-medium flex flex-row gap-2 text-center items-center justify-center -mt-4 mb-8">
          <p className="flex flex-row gap-2 items-center justify-center">
            Are you sure you want to permanently delete this note? This action
            cannot be undone!
          </p>
        </div>
      ),
      onSubmit: handleDeleteSubmit,
      submitLabel: "Delete",
      submitIcon: faTrash,
      submitLoading: isDeleting,
      submitColor: "bg-error hover:bg-red-500 text-white",
    });
  };

  const handleDeleteSubmit = async () => {
    if (incomeNote?.id) {
      try {
        setIsDeleting(true);
        await incomeNoteApi.deleteIncomeNote(incomeNote?.id);
        setShowModal(false);
        setAlert({
          message: `Note with ID-${incomeNote?.id} has been deleted.`,
          type: "info",
          display: true,
        });
        navigate("/my-notes");
      } catch (error) {
        console.error(error);
        setShowModal(false);
        setAlert({
          message: `Error deleting Note with ID-${incomeNote?.id}.`,
          type: "error",
          display: true,
        });
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <>
      {currentUser && (
        <button
          className={`bg-error text-brown font-bold py-2 px-4 rounded w-full gap-2 flex items-center justify-center`}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faTrash} />
          <span>{"DELETE"}</span>
        </button>
      )}
    </>
  );
};

export default DeleteNoteButton;
