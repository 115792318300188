/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import { incomeNoteApi } from "../../api";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";
import UserNoteCardGrid from "../../components/notes/UserNoteCardGrid";
import debounce from "lodash/debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

interface MyNotesProps {
  props?: any;
}

const MyNotes = ({ props }: MyNotesProps) => {
  const { currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [myNotes, setMyNotes] = useState<MortgageOrIncomeNote[]>([]);
  const [status, setStatus] = useState<string>("loading");
  const [inputValue, setInputValue] = useState<string>(
    searchParams.get("q") || ""
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get("q") || ""
  );

  const navigate = useNavigate();

  const fetchMyNotes = async (showLoading = true, search?: string) => {
    if (currentUser) {
      if (showLoading) setStatus("loading");
      try {
        const res: MortgageOrIncomeNote[] = await incomeNoteApi.fetchByUserId(
          currentUser.id,
          search
        );
        setMyNotes(res);
      } finally {
        if (showLoading) setStatus("loaded");
      }
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTerm(value);
        fetchMyNotes(false, value);
        if (value) {
          setSearchParams({ q: value });
        } else {
          setSearchParams({});
        }
      }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    const queryParam = searchParams.get("q");
    if (queryParam) {
      setInputValue(queryParam);
      setSearchTerm(queryParam);
      fetchMyNotes(true, queryParam);
    } else {
      fetchMyNotes();
    }
  }, [currentUser]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col px-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full gap-4 mb-8 px-2 sm:px-7 md:px-4 lg:px-6">
          <div className="flex-shrink-0">
            <h1 className="text-2xl text-heading-gray font-black hidden md:block">
              My Notes
            </h1>
          </div>
          <div className="flex flex-row items-center gap-4 w-full md:w-[400px] lg:w-[500px] xl:w-[600px]">
            <input
              type="text"
              placeholder="Search by address or external ID..."
              value={inputValue}
              onChange={handleSearch}
              className="w-full py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {currentUser?.allow_posting && (
              <div className="hidden md:block flex-shrink-0">
                <Tooltip
                  id="create-note"
                  content="Create Note"
                  place="left"
                  className="bg-brown text-white font-semibold"
                />
                <button
                  className="btn btn-accent whitespace-nowrap"
                  onClick={() => navigate("/create-note")}
                  data-tooltip-id="create-note"
                >
                  <FontAwesomeIcon icon={faPlus} className="px-0.5" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full">
          <UserNoteCardGrid
            notes={myNotes}
            status={status}
            fetchSavedNotes={fetchMyNotes}
            searchTerm={searchTerm}
          />
        </div>
      </div>
    </div>
  );
};

export default MyNotes;
