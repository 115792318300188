/* eslint-disable import/no-anonymous-default-export */

import axios from "../utils/axios";
import ApiTokenSupport from "./token.support";
import {
  DocumentType,
  FullIncomeNote,
} from "../interfaces/incomeNote.interface";
import { ImageItem } from "../components/postNote/ImageUploadPreview";
import { DocumentItem } from "../components/postNote/DocumentUpload";
import { parseAddressComponents } from "../helpers/parseAddressComponents";
import MortgageOrIncomeNote from "../redux/interfaces/mortgageNote.redux.interface";

class IncomeNoteApi extends ApiTokenSupport {
  async fetchIncomeNote(
    noteID: number,
    userID?: number
  ): Promise<FullIncomeNote> {
    const response = await axios.post<FullIncomeNote>(
      `/api/mortgageNotes/incomeNote/fetchById`,
      {
        incomeNoteID: noteID,
        ...(userID && { user_id: userID }),
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async toggleSavedNote(data: {
    user_id: number;
    income_note_id: number;
    is_interested: boolean;
    formData?: FormData;
    offer_amount?: number;
  }): Promise<any> {
    const payload = {
      user_id: data.user_id,
      income_note_id: data.income_note_id,
      is_interested: data.is_interested,
      ...(data.offer_amount && { offer_amount: data.offer_amount }),
    };

    // If formData is provided, use it and append the payload
    if (data.formData) {
      console.log("formData", data.formData);
      data.formData.append("data", JSON.stringify(payload));
      return axios
        .post(
          `/api/mortgageNotes/savedIncomeNote/toggle`,
          data.formData,
          this.withAuthorization({
            "Content-Type": "multipart/form-data",
          })
        )
        .then((resp) => resp.data);
    }

    // Otherwise, send regular JSON payload
    return axios
      .post(
        `/api/mortgageNotes/savedIncomeNote/toggle`,
        payload,
        this.withAuthorization()
      )
      .then((resp) => resp.data);
  }

  async draft(
    formData: any,
    imageItems: ImageItem[],
    documentItems: DocumentItem[],
    fullAddress?: string
  ): Promise<any> {
    // Parse address components
    const addressComponents = formData.addressData?.place_id
      ? await parseAddressComponents(formData.addressData.place_id, fullAddress)
      : null;

    // Create form data for file upload
    const apiFormData = new FormData();

    // Add images with metadata
    const imageMetadata: Record<string, { alt: string }> = {};
    imageItems.forEach((image) => {
      apiFormData.append("images", image.file);
      imageMetadata[image.file.name] = { alt: image.alt };
    });

    // Add documents with metadata
    const documentMetadata: Record<string, { documentType: DocumentType }> = {};
    documentItems.forEach((doc) => {
      apiFormData.append("documents", doc.file);
      documentMetadata[doc.file.name] = {
        documentType: doc.type as DocumentType,
      };
    });

    // Construct the API payload with snake_case keys and proper structure
    const payload = {
      // Address fields from parsed components
      house_number: addressComponents?.houseNumber || "",
      pre_direction: addressComponents?.preDirection || "",
      street: addressComponents?.street || "",
      suffix: addressComponents?.suffix || "",
      post_direction: addressComponents?.postDirection || "",
      unit_number: addressComponents?.unitNumber || "",
      unit_type: addressComponents?.unitType || "",
      city: addressComponents?.city || "",
      state: addressComponents?.state || "",
      zip: addressComponents?.zip || "",
      full_address: formData.addressData?.description || "",
      google_place_id: formData.addressData?.place_id || "",

      // Property details
      property_type: formData.propertyType,
      occupancy_status: formData.occupancyStatus,
      performance_status: formData.performanceStatus,
      bedrooms: Number(formData.bedrooms),
      bathrooms: Number(formData.bathrooms),
      square_footage: Number(formData.squareFootage),
      estimated_market_value: Number(
        formData.estimatedMarketValue.replace(/[^\d.]/g, "")
      ),

      // Loan details
      loan_type: formData.loanType,
      note_type: formData.noteType,
      lien_position: formData.lienPosition,
      asking_amount: Number(formData.askingAmount.replace(/[^\d.]/g, "")),
      origination_date: new Date(formData.originationDate),
      maturity_date: new Date(formData.maturityDate),
      initial_loan_amount: Number(
        formData.initialLoanAmount.replace(/[^\d.]/g, "")
      ),
      current_loan_amount: Number(
        formData.currentLoanAmount.replace(/[^\d.]/g, "")
      ),
      loan_payment_amount: Number(
        formData.loanPaymentAmount.replace(/[^\d.]/g, "")
      ),
      total_payoff: Number(formData.totalPayoff.replace(/[^\d.]/g, "")),
      borrower_down_payment: Number(
        formData.borrowerDownPayment.replace(/[^\d.]/g, "")
      ),
      interest_rate: Number(formData.interestRate),
      loan_term: Number(formData.loanTerm),

      // Additional information
      seller_comments: formData.sellerComments,
      image_metadata: imageMetadata,
      document_metadata: documentMetadata,
      external_id: formData.external_id || "",
      lat: formData.lat,
      lng: formData.lng,
      useGoogleStreetView: formData.useGoogleStreetView,
      accept_docs: formData.acceptDocs,
      accept_offers: formData.acceptOffers,
    };

    apiFormData.append("data", JSON.stringify(payload));

    const response = await axios.post<any>(
      "/api/mortgageNotes/incomeNote/draft",
      apiFormData,
      this.withAuthorization({
        "Content-Type": "multipart/form-data",
      })
    );

    return response.data;
  }

  async post(incomeNoteID: number): Promise<any> {
    const response = await axios.post<any>(
      `/api/mortgageNotes/incomeNote/post`,
      {
        note_id: incomeNoteID,
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async fetchByUserId(
    userID: number,
    search?: string
  ): Promise<MortgageOrIncomeNote[]> {
    const response = await axios.post<MortgageOrIncomeNote[]>(
      `/api/mortgageNotes/incomeNote/fetchByUserId`,
      {
        userID,
        search,
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async deleteIncomeNote(incomeNoteID: number): Promise<any> {
    const response = await axios.delete<any>(
      `/api/mortgageNotes/incomeNote/delete-note/${incomeNoteID}`,
      this.withAuthorization()
    );
    return response.data;
  }

  async fetchSavedIncomeNotes(userID: number): Promise<any> {
    const response = await axios.post<any>(
      `/api/mortgageNotes/savedIncomeNote/fetchAllSavedNotes`,
      {
        user_id: userID,
      },
      this.withAuthorization()
    );
    return response.data;
  }

  async update(
    noteId: number,
    formData: any,
    imageItems: ImageItem[],
    documentItems: DocumentItem[],
    fullAddress?: string
  ): Promise<any> {
    // Parse address components
    const addressComponents = formData.addressData?.place_id
      ? await parseAddressComponents(formData.addressData.place_id, fullAddress)
      : null;

    // Create form data for file upload
    const apiFormData = new FormData();

    // Add images with metadata
    const imageMetadata: Record<string, { alt: string }> = {};
    imageItems.forEach((image) => {
      apiFormData.append("images", image.file);
      imageMetadata[image.file.name] = { alt: image.alt };
    });

    // Add documents with metadata
    const documentMetadata: Record<string, { documentType: DocumentType }> = {};
    documentItems.forEach((doc) => {
      apiFormData.append("documents", doc.file);
      documentMetadata[doc.file.name] = {
        documentType: doc.type as DocumentType,
      };
    });

    // Construct the API payload with snake_case keys and proper structure
    const payload = {
      // Address fields from parsed components
      house_number: addressComponents?.houseNumber || "",
      pre_direction: addressComponents?.preDirection || "",
      street: addressComponents?.street || "",
      suffix: addressComponents?.suffix || "",
      post_direction: addressComponents?.postDirection || "",
      unit_number: addressComponents?.unitNumber || "",
      unit_type: addressComponents?.unitType || "",
      city: addressComponents?.city || "",
      state: addressComponents?.state || "",
      zip: addressComponents?.zip || "",
      full_address: formData.addressData?.description || "",
      google_place_id: formData.addressData?.place_id || "",

      // Property details
      property_type: formData.propertyType,
      occupancy_status: formData.occupancyStatus,
      performance_status: formData.performanceStatus,
      bedrooms: Number(formData.bedrooms),
      bathrooms: Number(formData.bathrooms),
      square_footage: Number(formData.squareFootage),
      estimated_market_value: Number(
        formData.estimatedMarketValue.toString().replace(/[^\d.]/g, "")
      ),

      // Loan details
      loan_type: formData.loanType,
      note_type: formData.noteType || "Mortgage",
      lien_position: formData.lienPosition,
      asking_amount: Number(
        formData.askingAmount.toString().replace(/[^\d.]/g, "")
      ),
      origination_date: new Date(formData.originationDate),
      maturity_date: new Date(formData.maturityDate),
      initial_loan_amount: Number(
        formData.initialLoanAmount.toString().replace(/[^\d.]/g, "")
      ),
      current_loan_amount: Number(
        formData.currentLoanAmount.toString().replace(/[^\d.]/g, "")
      ),
      loan_payment_amount: Number(
        formData.loanPaymentAmount.toString().replace(/[^\d.]/g, "")
      ),
      total_payoff: Number(
        formData.totalPayoff.toString().replace(/[^\d.]/g, "")
      ),
      borrower_down_payment: Number(
        formData.borrowerDownPayment.toString().replace(/[^\d.]/g, "")
      ),
      interest_rate: Number(formData.interestRate),
      loan_term: Number(formData.loanTerm),

      // Additional information
      seller_comments: formData.sellerComments,
      image_metadata: imageMetadata,
      document_metadata: documentMetadata,
      external_id: formData.external_id || "",
      lat: formData.lat,
      lng: formData.lng,
      useGoogleStreetView: formData.useGoogleStreetView,
      accept_docs: formData.acceptDocs,
      accept_offers: formData.acceptOffers,
    };

    apiFormData.append("data", JSON.stringify(payload));

    const response = await axios.put<any>(
      `/api/mortgageNotes/incomeNote/${noteId}`,
      apiFormData,
      this.withAuthorization({
        "Content-Type": "multipart/form-data",
      })
    );

    return response.data;
  }
}

export default new IncomeNoteApi();
