import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";
import { TOKEN_ID } from "../contexts/AuthContext";
import { DecodedToken } from "../contexts/AuthContext";

const TokenRefreshGuard: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { refreshToken } = useAuth();

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const token = localStorage.getItem(TOKEN_ID);
      if (token) {
        const decoded: DecodedToken = jwt_decode(token);
        const issuedAt = new Date(decoded.iat * 1000);
        const currentTime = new Date();
        const timeSinceIssued = currentTime.getTime() - issuedAt.getTime();

        // If token is more than 60 minutes old, refresh it
        if (timeSinceIssued > 600000) {
          await refreshToken();
        }
      }
    };

    checkAndRefreshToken();
  }, [location.pathname, refreshToken]);

  return <>{children}</>;
};

export default TokenRefreshGuard;
