import { Tooltip as ReactTooltip } from "react-tooltip";

interface TooltipProps {
  id: string;
  place?: "top" | "right" | "bottom" | "left";
  className?: string;
  content: any;
  disable?: boolean;
  variant?: "dark" | "light" | "success" | "warning" | "error" | "info";
  props?: any;
}

const Tooltip = ({
  id,
  place = "top",
  className,
  content,
  disable = false,
  variant = "dark",
  props,
}: TooltipProps) => {
  const globalClasses = "!z-[999]";

  return (
    <ReactTooltip
      id={id}
      place={place}
      variant={variant}
      className={`${globalClasses} ${className}`}
      disabled={disable}
      {...props}
    >
      {content}
    </ReactTooltip>
  );
};

export default Tooltip;
