import React, { useState } from "react";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { userApi } from "../api";
import { useNavigate, useParams } from "react-router-dom";

interface ResetPasswordProps {
  props?: any;
}

const ResetPassword = ({ props }: ResetPasswordProps) => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordData, setPasswordData] = useState<{
    password: string;
    repeatPassword: string;
  }>({
    password: "",
    repeatPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState<{
    password: string;
    repeatPassword: string;
  }>({
    password: "",
    repeatPassword: "",
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
    // Clear errors when user starts typing
    setPasswordErrors({ ...passwordErrors, [name]: "" });
  };

  const navigate = useNavigate();

  const validatePassword = () => {
    let isValid = true;
    const newErrors = {
      password: "",
      repeatPassword: "",
    };

    // Check if fields are empty
    if (!passwordData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    if (!passwordData.repeatPassword) {
      newErrors.repeatPassword = "Please confirm your password";
      isValid = false;
    }

    // Check password length
    if (passwordData.password && passwordData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters";
      isValid = false;
    }

    // Check if passwords match
    if (
      passwordData.password &&
      passwordData.repeatPassword &&
      passwordData.password !== passwordData.repeatPassword
    ) {
      newErrors.repeatPassword = "Passwords do not match";
      isValid = false;
    }

    setPasswordErrors(newErrors);
    return isValid;
  };

  const submitPassword = async () => {
    if (!token) {
      return;
    }
    if (!validatePassword()) {
      return;
    }
    try {
      setIsLoading(true);
      const resp: { success: boolean; message: string } =
        await userApi.resetPassword(passwordData.password, token);
      if (resp.success) {
        navigate("/login", {
          state: { message: resp.message },
        });
      } else {
        setIsLoading(false);
        return navigate("/invalid", {
          state: { message: resp.message },
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center mt-4">
        <div className="flex flex-col items-center justify-center h-full w-full xs:px-4 sm:px-12 md:px-28 -mt-5">
          <div className="w-full max-w-96">
            <input
              type="password"
              name="password"
              placeholder="Create Password"
              className="input input-bordered border-light-brown focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full"
              value={passwordData.password}
              onChange={handlePasswordChange}
            />
            {passwordErrors.password && (
              <p className="text-red-500 text-sm my-1">
                {passwordErrors.password}
              </p>
            )}
          </div>
          <div className="w-full max-w-96 mt-1">
            <input
              type="password"
              name="repeatPassword"
              placeholder="Repeat Password"
              className="input input-bordered border-light-brown focus:border-light-brown placeholder:text-light-brown bg-white  text-brown w-full"
              value={passwordData.repeatPassword}
              onChange={handlePasswordChange}
            />
            {passwordErrors.repeatPassword && (
              <p className="text-red-500 text-sm my-1">
                {passwordErrors.repeatPassword}
              </p>
            )}
          </div>{" "}
          <div className="w-full  max-w-96 mt-1">
            <button
              className="btn btn-accent w-full text-brown font-extrabold"
              onClick={submitPassword}
            >
              {isLoading ? <LoadingSpinner /> : "RESET PASSWORD"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
