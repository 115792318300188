import { PropsWithChildren, useState } from "react";
import { ModalOptionsProps } from "../../contexts/ModalContext";
import useModal from "../../hooks/useModal";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";
import OutsideClickHandler from "react-outside-click-handler";
import { motion, AnimatePresence, MotionStyle } from "framer-motion";
import ButtonLoader from "../loading/ButtonLoader";
import LoadingSpinner from "../loading/LoadingSpinner";

export const ModalLayout = ({
  children,
  disableOutsideClick,
  style,
  onCancel,
}: PropsWithChildren<{
  disableOutsideClick?: boolean;
  style?: MotionStyle;
  onCancel?: () => void;
}>) => {
  const { setShowModal } = useModal();

  const closeModal = () => {
    setShowModal(false);
    window.history.pushState({}, "", window.location.pathname);
    if (onCancel) onCancel();
  };

  return (
    <AnimatePresence>
      <motion.div
        style={{
          backgroundColor: "rgba(0,0,0,0.69)",
          ...style,
        }}
        className={
          "fixed bottom-0 left-0 right-0 top-0 z-40 flex max-h-screen items-center justify-center bg-title-text-light"
        }
        initial={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <div className="flex max-h-screen w-full items-center justify-center">
          <motion.div
            className="max-h-screen w-11/12 rounded-lg bg-white shadow-lg drop-shadow-2xl dark:bg-white sm:w-8/12 md:w-7/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12"
            initial={{ opacity: 0, scale: 0.5 }}
            transition={{
              opacity: { duration: 0.2 },
              scale: { duration: 0.18 },
            }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="flex max-h-screen columns-1 flex-col justify-center relative z-50">
              <OutsideClickHandler
                disabled={disableOutsideClick}
                onOutsideClick={() => closeModal()}
                children={children}
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const ModalCard = ({
  title,
  titleImageBackground,
  profileImage,
  body,
  onSubmit,
  onCancel,
  submitLabel,
  submitLoading,
  cancelLabel,
  hideButtons,
  hideCancelButton,
  disableOutsideClick,
  modalStyle,
  submitIcon,
  submitColor,
  submitTextColor,
}: ModalOptionsProps) => {
  const { setShowModal, showModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const handleSubmitWithLoader = async (data: any) => {
    setOnSubmitting(true);
    if (typeof onSubmit === "function") await onSubmit(data);
    setOnSubmitting(false);
  };

  const handleCancel = async () => {
    setShowModal(false);
    window.history.pushState({}, "", window.location.pathname);
    if (onCancel) onCancel();
  };

  const [isHovering, setIsHovering] = useState<boolean>(false);

  const titleImageBackgroundStyle = titleImageBackground
    ? {
        backgroundImage: `url(${titleImageBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "300px",
        width: "100%",
      }
    : {};

  return (
    <>
      {showModal ? (
        <ModalLayout
          disableOutsideClick={disableOutsideClick}
          style={modalStyle}
          onCancel={handleCancel}
        >
          <div
            className="flex items-start justify-between px-2 pt-2 rounded-t-lg"
            style={titleImageBackgroundStyle}
          >
            <div className="w-8"></div>
            <h1 className="text-brown dark:text-brown mt-4 select-none text-center text-xxl font-semibold md:text-xl ">
              {title}
            </h1>
            <div className="mb-6">
              <DeleteButton onClick={handleCancel} />
            </div>
          </div>

          {profileImage && (
            <div className="relative">
              <div className="absolute -bottom-16 left-8 z-[10]">
                <div className="w-32 h-32 rounded-full border-4 border-white bg-gray-200 overflow-hidden shadow-lg">
                  <img
                    src={profileImage}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Title Image Background */}

          <div className="w-full px-4 text-center text-text-dark dark:text-text-light pb-4">
            {body}
          </div>
          {!hideButtons && (
            <div className="flex items-center justify-center pb-4">
              {!hideCancelButton && (
                <Button
                  className="btn btn-ghost mr-1 w-32 text-lg font-bold  text-gray-400 hover:bg-gray-200"
                  onClick={handleCancel}
                  disabled={onSubmitting}
                >
                  {cancelLabel || "CANCEL"}
                </Button>
              )}
              <Button
                className={`btn ml-1 w-32 ${
                  submitColor
                    ? `${submitColor}`
                    : "bg-primary  hover:bg-primary"
                } text-lg font-bold  hover:drop-shadow-lg disabled:bg-hover-back-light disabled:text-gray-500 dark:text-text-light disabled:dark:bg-hover-card-dark disabled:dark:text-gray-800`}
                onClick={!onSubmitting ? handleSubmitWithLoader : () => {}}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                icon={!onSubmitting ? submitIcon : undefined}
              >
                {onSubmitting || submitLoading ? (
                  <LoadingSpinner
                    className={`h-6 w-6 ${submitColor ? submitColor : ""} `}
                  />
                ) : (
                  submitLabel ?? "SUBMIT"
                )}
              </Button>
            </div>
          )}
        </ModalLayout>
      ) : (
        showModal && (
          <ModalLayout>
            <div className="">
              <div className="flex justify-between">
                <div className="w-10"></div>
                <h1 className="mt-4 select-none text-center text-xxl font-bold text-error">
                  Access Denied
                </h1>
                <DeleteButton onClick={handleCancel} />
              </div>
            </div>
            <div className="my-4 px-8">
              <p className="select-none text-center text-xl text-title-text-light dark:text-title-text-dark">
                Sorry you don't have sufficient privileges to view this page.
              </p>
            </div>

            <div className="flex items-center justify-center">
              <button
                className="btn mr-1 bg-white text-lg font-bold text-text-dark hover:text-text-light dark:bg-black dark:text-text-light hover:dark:border-white"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </ModalLayout>
        )
      )}
    </>
  );
};

export default ModalCard;
