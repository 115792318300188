import {
  AppStatus,
  LienPosition,
  LoanType,
  NoteType,
} from "../../interfaces/incomeNote.interface";
import { NoteStatus } from "../../interfaces/mortgageNote.interface";

export type NoteDataType = "mortgage" | "income";

export enum PerformanceStatusEnum {
  Performing = "Performing",
  NonPerforming = "NonPerforming",
  UnderPerforming = "UnderPerforming",
}

export enum OccupancyStatusEnum {
  OwnerOccupied = "Owner Occupied",
  NonOwnerOccupied = "Non Owner Occupied",
  Vacant = "Vacant",
  Unknown = "Unknown",
}

export enum PropertyTypeEnum {
  SingleFamily = "Single Family",
  Duplex = "Duplex",
  MultiUnit3Plus = "Multi Unit 3+",
  Townhouse = "Townhouse",
  Condo = "Condo",
  Land = "Land",
  Other = "Other",
}

export enum LienPositionEnum {
  First = "1st",
  Second = "2nd",
  Third = "3rd",
}

export enum NoteTypeEnum {
  Mortgage = "Mortgage",
  DeedOfTrust = "Deed of Trust",
  ContractForDeed = "Contract For Deed",
}

export enum LoanTypeEnum {
  InterestOnly = "Interest Only",
  AmortizedLoan = "Amortized Loan",
}

export default interface MortgageOrIncomeNote {
  // Address
  id: number;
  street_number: string;
  city: string;
  state: string;
  postal_code: string;
  type?: string;
  bedrooms?: number;
  bathrooms?: number;
  sqrfeet?: number;
  note_data_type: NoteDataType;
  loan_amount: number;
  loan_to_value: number;
  loan_term: number;
  interest_rate: number;
  highlight: string;
  loan_payment_amount: number;
  property_image: string;
  is_saved?: boolean;
  is_interested?: boolean;
  accept_docs?: boolean;
  accept_offers?: boolean;
  offer_amount?: number;
  app_status: AppStatus;
  external_id: string;
}

// Helper type for serializable dates
export interface SerializableDateRange {
  start: string | null;
  end: string | null;
}

export interface FetchNotesParams {
  addressState?: string[];
  searchQuery?: string;
  targetMin?: number;
  targetMax?: number;
  loanMin?: number;
  loanMax?: number;
  status?: NoteStatus[];
  performanceStatus?: PerformanceStatusEnum[];
  occupancyStatus?: OccupancyStatusEnum[];
  propertyType?: PropertyTypeEnum[];
  loanType?: LoanTypeEnum[];
  noteType?: NoteTypeEnum[];
  lienPosition?: LienPositionEnum[];
  initialLoanAmount?: { min: number | null; max: number | null };
  currentLoanAmount?: { min: number | null; max: number | null };
  loanPaymentAmount?: { min: number | null; max: number | null };
  totalPayoff?: { min: number | null; max: number | null };
  estimatedMarketValue?: { min: number | null; max: number | null };
  borrowerDownPayment?: { min: number | null; max: number | null };
  askingAmount?: { min: number | null; max: number | null };
  interestRate?: { min: number | null; max: number | null };
  loanTerm?: { min: number | null; max: number | null };
  bedrooms?: { min: number | null; max: number | null };
  bathrooms?: { min: number | null; max: number | null };
  squareFootage?: { min: number | null; max: number | null };
  originationDate?: { start: Date | null; end: Date | null };
  maturityDate?: { start: Date | null; end: Date | null };
  offset?: number;
  limit?: number;
  user_id?: number | null;
}

// Extend FetchNotesParams to use string dates instead of Date objects
export interface SerializableFetchNotesParams
  extends Omit<FetchNotesParams, "originationDate" | "maturityDate"> {
  originationDate?: SerializableDateRange;
  maturityDate?: SerializableDateRange;
  addressState: string[];
  occupancyStatus: OccupancyStatusEnum[];
  propertyType: PropertyTypeEnum[];
  loanType: LoanTypeEnum[];
  noteType: NoteTypeEnum[];
  lienPosition: LienPositionEnum[];
  performanceStatus: PerformanceStatusEnum[];
  status: NoteStatus[];
}
