export const suffixes = [
  // Long forms and their abbreviations
  "Alley",
  "Aly",
  "Annex",
  "Anx",
  "Arcade",
  "Arc",
  "Avenue",
  "Ave",
  "Bayou",
  "Byu",
  "Beach",
  "Bch",
  "Bend",
  "Bnd",
  "Bluff",
  "Blf",
  "Bluffs",
  "Blfs",
  "Bottom",
  "Btm",
  "Boulevard",
  "Blvd",
  "Branch",
  "Br",
  "Bridge",
  "Brg",
  "Brook",
  "Brk",
  "Brooks",
  "Brks",
  "Burg",
  "Bg",
  "Burgs",
  "Bgs",
  "Bypass",
  "Byp",
  "Camp",
  "Cp",
  "Canyon",
  "Cyn",
  "Cape",
  "Cpe",
  "Causeway",
  "Cswy",
  "Center",
  "Ctr",
  "Centers",
  "Ctrs",
  "Circle",
  "Cir",
  "Cliff",
  "Clf",
  "Cliffs",
  "Clfs",
  "Club",
  "Clb",
  "Common",
  "Cmn",
  "Commons",
  "Cmns",
  "Corner",
  "Cor",
  "Corners",
  "Cors",
  "Course",
  "Crse",
  "Court",
  "Ct",
  "Courts",
  "Cts",
  "Cove",
  "Cv",
  "Coves",
  "Cvs",
  "Creek",
  "Crk",
  "Crescent",
  "Cres",
  "Crest",
  "Crst",
  "Crossing",
  "Xing",
  "Crossroad",
  "Xrd",
  "Curve",
  "Curv",
  "Dale",
  "Dl",
  "Dam",
  "Dm",
  "Divide",
  "Dv",
  "Drive",
  "Dr",
  "Drives",
  "Drs",
  "Estate",
  "Est",
  "Estates",
  "Ests",
  "Expressway",
  "Expy",
  "Extension",
  "Ext",
  "Extensions",
  "Exts",
  "Fall",
  "Fall",
  "Falls",
  "Fls",
  "Ferry",
  "Fry",
  "Field",
  "Fld",
  "Fields",
  "Flds",
  "Flat",
  "Flt",
  "Flats",
  "Flts",
  "Ford",
  "Frd",
  "Fords",
  "Frds",
  "Forest",
  "Frst",
  "Forge",
  "Frg",
  "Forges",
  "Frgs",
  "Fork",
  "Frk",
  "Forks",
  "Frks",
  "Fort",
  "Ft",
  "Freeway",
  "Fwy",
  "Garden",
  "Gdn",
  "Gardens",
  "Gdns",
  "Gateway",
  "Gtwy",
  "Glen",
  "Gln",
  "Glens",
  "Glns",
  "Green",
  "Grn",
  "Greens",
  "Grns",
  "Grove",
  "Grv",
  "Groves",
  "Grvs",
  "Harbor",
  "Hbr",
  "Harbors",
  "Hbrs",
  "Haven",
  "Hvn",
  "Heights",
  "Hts",
  "Highway",
  "Hwy",
  "Hill",
  "Hl",
  "Hills",
  "Hls",
  "Hollow",
  "Holw",
  "Inlet",
  "Inlt",
  "Island",
  "Is",
  "Islands",
  "Iss",
  "Isle",
  "Isle",
  "Junction",
  "Jct",
  "Junctions",
  "Jcts",
  "Key",
  "Ky",
  "Keys",
  "Kys",
  "Knoll",
  "Knl",
  "Knolls",
  "Knls",
  "Lake",
  "Lk",
  "Lakes",
  "Lks",
  "Land",
  "Land",
  "Landing",
  "Lndg",
  "Lane",
  "Ln",
  "Light",
  "Lgt",
  "Lights",
  "Lgts",
  "Loaf",
  "Lf",
  "Lock",
  "Lck",
  "Locks",
  "Lcks",
  "Lodge",
  "Ldg",
  "Loop",
  "Loop",
  "Mall",
  "Mall",
  "Manor",
  "Mnr",
  "Manors",
  "Mnrs",
  "Meadow",
  "Mdw",
  "Meadows",
  "Mdws",
  "Mews",
  "Mews",
  "Mill",
  "Ml",
  "Mills",
  "Mls",
  "Mission",
  "Msn",
  "Motorway",
  "Mtwy",
  "Mount",
  "Mt",
  "Mountain",
  "Mtn",
  "Mountains",
  "Mtns",
  "Neck",
  "Nck",
  "Orchard",
  "Orch",
  "Oval",
  "Oval",
  "Overpass",
  "Opas",
  "Park",
  "Park",
  "Parks",
  "Park",
  "Parkway",
  "Pkwy",
  "Parkways",
  "Pkwy",
  "Pass",
  "Pass",
  "Passage",
  "Psge",
  "Path",
  "Path",
  "Pike",
  "Pike",
  "Pine",
  "Pne",
  "Pines",
  "Pnes",
  "Place",
  "Pl",
  "Plain",
  "Pln",
  "Plains",
  "Plns",
  "Plaza",
  "Plz",
  "Point",
  "Pt",
  "Points",
  "Pts",
  "Port",
  "Prt",
  "Ports",
  "Prts",
  "Prairie",
  "Pr",
  "Radial",
  "Radl",
  "Ramp",
  "Ramp",
  "Ranch",
  "Rnch",
  "Rapid",
  "Rpd",
  "Rapids",
  "Rpds",
  "Rest",
  "Rst",
  "Ridge",
  "Rdg",
  "Ridges",
  "Rdgs",
  "River",
  "Riv",
  "Road",
  "Rd",
  "Roads",
  "Rds",
  "Route",
  "Rte",
  "Row",
  "Row",
  "Rue",
  "Rue",
  "Run",
  "Run",
  "Shoal",
  "Shl",
  "Shoals",
  "Shls",
  "Shore",
  "Shr",
  "Shores",
  "Shrs",
  "Skyway",
  "Skwy",
  "Spring",
  "Spg",
  "Springs",
  "Spgs",
  "Spur",
  "Spur",
  "Square",
  "Sq",
  "Squares",
  "Sqs",
  "Station",
  "Sta",
  "Stravenue",
  "Stra",
  "Stream",
  "Strm",
  "Street",
  "St",
  "Streets",
  "Sts",
  "Summit",
  "Smt",
  "Terrace",
  "Ter",
  "Throughway",
  "Trwy",
  "Trace",
  "Trce",
  "Track",
  "Trak",
  "Trafficway",
  "Trfy",
  "Trail",
  "Trl",
  "Trailer",
  "Trlr",
  "Tunnel",
  "Tunl",
  "Turnpike",
  "Tpke",
  "Underpass",
  "Upas",
  "Union",
  "Un",
  "Unions",
  "Uns",
  "Valley",
  "Vly",
  "Valleys",
  "Vlys",
  "Viaduct",
  "Via",
  "View",
  "Vw",
  "Views",
  "Vws",
  "Village",
  "Vlg",
  "Villages",
  "Vlgs",
  "Ville",
  "Vl",
  "Vista",
  "Vis",
  "Walk",
  "Walk",
  "Wall",
  "Wall",
  "Way",
  "Way",
  "Well",
  "Wl",
  "Wells",
  "Wls",
];
