interface Configs {
  name: string;
  sicUsers: number[];
}

const configs: Configs = {
  name: "Income Note Exchange",
  sicUsers: [1, 9],
};

export default configs;

export const isSicUser = (userId: number): boolean => {
  return configs.sicUsers.includes(userId);
};
