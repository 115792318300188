import React from "react";
import MortgageNote from "../../redux/interfaces/mortgageNote.redux.interface";
import NoteCard from "./NoteCard";
import NoteCardSkeleton from "./NoteCardSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FetchedIncomeNote } from "../../interfaces/incomeNote.interface";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";

interface NoteCardGridProps {
  notes: MortgageOrIncomeNote[];
  status: string;
  hideRibbon?: boolean;
  hideSaved?: boolean;
  fetchSavedNotes?: (showLoading?: boolean) => void;
}

const NoteCardGrid = ({
  notes,
  status,
  hideRibbon = false,
  hideSaved = false,
  fetchSavedNotes,
}: NoteCardGridProps) => {
  const skeletonCount = 3;

  if (notes.length === 0 && status !== "loading") {
    return (
      <div className="w-full h-full flex items-center justify-center py-12 mb-12">
        <span className="font-extrabold text-warning">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="mx-4 text-lg"
          />
          No Notes match the specified filters, please adjust your search
        </span>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-stretch justify-center w-full pb-12 z-0">
      {notes.map((note: MortgageNote | FetchedIncomeNote, index: number) => (
        <div key={index} className="flex">
          <NoteCard
            note={note as MortgageOrIncomeNote}
            hideRibbon={hideRibbon}
            fetchSavedNotes={fetchSavedNotes}
            hideSaved={hideSaved}
          />
        </div>
      ))}
      {status === "loading" &&
        Array(skeletonCount)
          .fill(null)
          .map((_, index) => (
            <div key={`skeleton-${index}`} className="flex">
              <NoteCardSkeleton />
            </div>
          ))}
    </div>
  );
};

export default NoteCardGrid;
