import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPencil,
  faTrash,
  faEye,
  faHashtag,
  faTriangleExclamation,
  faPaperPlane,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { hashNote } from "../../utils/hashNote";
import { FetchedIncomeNote } from "../../interfaces/incomeNote.interface";
import MortgageNote from "../../redux/interfaces/mortgageNote.redux.interface";
import MortgageOrIncomeNote from "../../redux/interfaces/mortgageNote.redux.interface";
import Tooltip from "../tooltip/Tooltip";
import useModal from "../../hooks/useModal";
import useAlert from "../../hooks/useAlert";
import { incomeNoteApi } from "../../api";
import no_image_found from "../../assets/images/no_image_found.png";
import useAuth from "../../hooks/useAuth";

interface UserNoteCardProps {
  note: MortgageOrIncomeNote;
  fetchSavedNotes?: (showLoading?: boolean) => void;
}

const UserNoteCard = ({ note, fetchSavedNotes }: UserNoteCardProps) => {
  const navigate = useNavigate();
  const { openModalWith, setShowModal } = useModal();
  const { setAlert } = useAlert();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const {
    street_number,
    city,
    state,
    postal_code,
    type,
    property_image,
    app_status,
    external_id,
  } = note;

  const address = `${street_number} ${city}, ${state} ${postal_code}`;

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "Drafted":
        return "badge-warning";
      case "Pending":
        return "badge-info";
      case "Active":
        return "badge-accent";
      case "Rejected":
        return "badge-error";
      case "Expired":
        return "badge-neutral";
      default:
        return "badge-ghost";
    }
  };

  const onNoteClick = async (note: MortgageNote | FetchedIncomeNote) => {
    const noteHash = hashNote(note.id, note.note_data_type);
    navigate(`/notes/${noteHash}`, { state: { from: "/my-notes" } });
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/notes/${hashNote(note.id, note.note_data_type)}/edit`);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      openModalWith({
        title: "Delete Note",
        body: (
          <div className="text-error font-medium flex flex-row gap-2 text-center items-center justify-center -mt-4 mb-8">
            <p className="flex flex-row gap-2 items-center justify-center">
              Are you sure you want to permanently delete this note? This action
              cannot be undone!
            </p>
          </div>
        ),
        onSubmit: handleDeleteSubmit,
        submitLabel: "Delete",
        submitIcon: faTrash,
        submitLoading: isDeleting,
        submitColor: "bg-error hover:bg-red-500 text-white",
      });
      fetchSavedNotes?.(false);
    } catch (error) {}
  };

  const handleDeleteSubmit = async () => {
    if (note?.id) {
      try {
        setIsDeleting(true);
        await incomeNoteApi.deleteIncomeNote(note?.id);
        setShowModal(false);
        setAlert({
          message: `Note with ID-${note?.id} has been deleted.`,
          type: "info",
          display: true,
        });
      } catch (error) {
        console.error(error);
        setShowModal(false);
        setAlert({
          message: `Error deleting Note with ID-${note?.id}.`,
          type: "error",
          display: true,
        });
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handlePostModal = () => {
    openModalWith({
      title: "Post Note",
      body: `Are you sure you want to post this note? ${
        currentUser?.allow_edit_posted_notes
          ? ""
          : "You cannot edit this note once posted."
      }`,
      onSubmit: handlePostNote,
      submitLabel: "Post",
      submitColor: "btn-accent text-black ",
      submitIcon: faPaperPlane,
      submitLoading: isPosting,
    });
  };

  const handlePostNote = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      setIsPosting(true);
      await incomeNoteApi.post(note.id);
    } catch (error) {
      console.error(error);
      setAlert({
        message: `Error posting note with ${
          note?.external_id ? "external ID" : "ID"
        }-${note?.external_id ? note?.external_id : note?.id}.`,
        type: "error",
        display: true,
      });
    } finally {
      setIsPosting(false);
      setShowModal(false);
      fetchSavedNotes?.(false);
    }
  };

  return (
    <div className="card bg-white shadow-xl flex flex-col h-full w-full sm:w-11/12 md:w-5/6 lg:w-full xl:w-11/12 2xl:w-5/6 mx-auto">
      <figure className="relative overflow-hidden h-40 sm:h-48 md:h-56 lg:h-64 xl:h-72 2xl:h-80">
        <img
          src={property_image || no_image_found}
          alt={address}
          className="w-full h-full object-cover"
        />
      </figure>
      <div className="card-body flex-grow flex flex-col justify-between p-3 sm:p-4 md:p-5 lg:p-6">
        <div>
          <div className="text-brown flex gap-1.5 items-center justify-start mb-2">
            <FontAwesomeIcon icon={faLocationDot} />
            <span>{address}</span>
          </div>
          <div className="text-brown flex gap-1.5 items-center justify-start mb-2 cursor-default">
            <React.Fragment key={`external-id-${note.id}`}>
              <Tooltip
                id={`external-id-${note.id}`}
                content={"External ID"}
                className="bg-brown text-white font-semibold"
              />
              <div
                className="flex gap-2 items-center"
                data-tooltip-id={`external-id-${note.id}`}
              >
                <FontAwesomeIcon icon={faHashtag} />
                <span>{external_id}</span>
              </div>
            </React.Fragment>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div
              className={`badge ${getStatusColor(
                app_status
              )} badge-lg rounded-lg font-medium`}
            >
              {app_status}
            </div>
            <div className="flex gap-2">
              {currentUser?.allow_posting && app_status === "Drafted" && (
                <React.Fragment key={`post-${note.id}`}>
                  <Tooltip
                    id={`post-${note.id}`}
                    content={"Post"}
                    className="bg-brown text-white font-semibold"
                  />
                  <button
                    className="btn btn-square btn-sm btn-accent"
                    onClick={handlePostModal}
                    data-tooltip-id={`post-${note.id}`}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} className="px-0.5" />
                  </button>
                </React.Fragment>
              )}

              {(app_status === "Drafted" ||
                (app_status === "Active" &&
                  currentUser?.allow_edit_posted_notes)) && (
                <React.Fragment key={`edit-${note.id}`}>
                  <Tooltip
                    id={`edit-${note.id}`}
                    content={"Edit"}
                    className="bg-brown text-white font-semibold"
                  />
                  <button
                    className="btn btn-square btn-sm btn-warning"
                    onClick={handleEdit}
                    data-tooltip-id={`edit-${note.id}`}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                </React.Fragment>
              )}
              <React.Fragment key={`delete-${note.id}`}>
                <Tooltip
                  id={`delete-${note.id}`}
                  content={"Delete"}
                  className="bg-brown text-white font-semibold"
                />
                <button
                  className="btn btn-square btn-sm btn-error"
                  onClick={handleDelete}
                  data-tooltip-id={`delete-${note.id}`}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </React.Fragment>
              <React.Fragment key={`view-${note.id}`}>
                <Tooltip
                  id={`view-${note.id}`}
                  content={note.app_status === "Active" ? "View" : "Preview"}
                  className="bg-brown text-white font-semibold"
                />
                <button
                  className="btn btn-square btn-sm btn-info"
                  onClick={() => onNoteClick(note)}
                  data-tooltip-id={`view-${note.id}`}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserNoteCard);
