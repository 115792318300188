import { User } from "../interfaces/user.interface";

export type Permission =
  | "allow_posting"
  | "allow_preview_24_hours"
  | "allow_preview_72_hours"
  | "allow_edit_contact_info";

// Map routes to required permissions
export const ROUTE_PERMISSIONS: Record<string, Permission[]> = {
  "/post-note": ["allow_posting"],
  "/my-notes": ["allow_posting"],
  "/notes/:noteHash/edit": ["allow_posting"],
  "/my-info": ["allow_edit_contact_info"],
};

// Helper function to check if user has required permissions
export const hasRequiredPermissions = (
  user: User | null,
  requiredPermissions: Permission[]
): boolean => {
  if (!user) return false;
  return requiredPermissions.every(
    (permission) => Boolean(user[permission]) === true
  );
};

// Helper to get permissions needed for a specific path
export const getRequiredPermissionsForPath = (path: string): Permission[] => {
  // Handle dynamic routes (replace :param with actual value)
  const normalizedPath = Object.keys(ROUTE_PERMISSIONS).find((route) => {
    const routeRegex = new RegExp(
      "^" + route.replace(/:[^/]+/g, "[^/]+") + "$"
    );
    return routeRegex.test(path);
  });

  return normalizedPath ? ROUTE_PERMISSIONS[normalizedPath] : [];
};
