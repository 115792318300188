import React, { ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoadingTable from "../components/loading/LoadingTable";
import {
  getRequiredPermissionsForPath,
  hasRequiredPermissions,
} from "./permissions";

interface PrivateRouteProps {
  children?: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <div className="bg-base-300 rounded-lg px-4 py-1 mb-48">
          <LoadingTable label="" />
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Check permissions for the current route
  const requiredPermissions = getRequiredPermissionsForPath(location.pathname);
  if (
    requiredPermissions.length > 0 &&
    !hasRequiredPermissions(currentUser, requiredPermissions)
  ) {
    // Redirect to home if user doesn't have required permissions
    return <Navigate to="/" replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default PrivateRoute;
