import React from "react";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";
import useAuth from "../../../hooks/useAuth";
import { isSicUser } from "../../../configs";

interface ConfirmationSlideProps {
  note: FullIncomeNote;
}

const ConfirmationSlide = ({ note }: ConfirmationSlideProps) => {
  const { contact_email, phone } = note.user;

  const { currentUser } = useAuth();

  return (
    <div className="w-full flex flex-col items-center justify-center px-8 pt-4 pb-4 text-center">
      <div className="w-16 h-16 bg-accent rounded-full flex items-center justify-center mb-4">
        <FontAwesomeIcon icon={faCheck} className="text-black text-3xl " />
      </div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        {!("loan_number" in note) && note.accept_docs ? "Documents" : "Offer"}{" "}
        Submitted Successfully!
      </h2>
      <p className="text-gray-600 max-w-md mb-6">
        {isSicUser(currentUser?.id || 0) ? (
          <>
            Thank you for your interest. Our team will review your{" "}
            {!("loan_number" in note) && note.accept_docs
              ? "documents"
              : "offer"}{" "}
            and contact you within the next 24-48 hours to discuss the next
            steps.
          </>
        ) : (
          <>
            Thank you for your interest. Our team will review your{" "}
            {!("loan_number" in note) && note.accept_docs
              ? "documents"
              : "offer"}{" "}
            and contact you within the next 24-48 hours to discuss the next
            steps.
          </>
        )}
      </p>
      <div className="flex flex-col gap-2 items-center text-sm text-gray-500">
        <p>
          <span className="font-semibold">Email:</span> {contact_email}
        </p>
        {phone && (
          <p>
            <span className="font-semibold">Phone:</span>{" "}
            {formatPhoneNumber(phone)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ConfirmationSlide;
