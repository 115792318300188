import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsFilter,
  faSquareXmark,
  faTrashXmark,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FilterState } from "./FilterForm";
import {
  updateFiltersAndFetch,
  deserializeDates,
} from "../../redux/slices/mortgageNotesSlice";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { SerializableFetchNotesParams } from "../../redux/interfaces/mortgageNote.redux.interface";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";

interface ActiveFiltersProps {
  onRemoveFilter: (field: keyof FilterState, value?: string | number) => void;
  setShowFilterDrawer: (show: boolean) => void;
}

const defaultFilters: SerializableFetchNotesParams = {
  addressState: [],
  occupancyStatus: [],
  propertyType: [],
  loanType: [],
  noteType: [],
  lienPosition: [],
  performanceStatus: [],
  initialLoanAmount: { min: null, max: null },
  currentLoanAmount: { min: null, max: null },
  loanPaymentAmount: { min: null, max: null },
  totalPayoff: { min: null, max: null },
  estimatedMarketValue: { min: null, max: null },
  borrowerDownPayment: { min: null, max: null },
  askingAmount: { min: null, max: null },
  interestRate: { min: null, max: null },
  loanTerm: { min: null, max: null },
  bedrooms: { min: null, max: null },
  bathrooms: { min: null, max: null },
  squareFootage: { min: null, max: null },
  originationDate: { start: null, end: null },
  maturityDate: { start: null, end: null },
  offset: 0,
  limit: 10,
  status: ["Available"],
};

const className = "btn btn-sm bg-gray-200 text-gray-800";
const iconClassName = "text-gray-600 text-lg";

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  onRemoveFilter,
  setShowFilterDrawer,
}) => {
  const filters = useSelector(
    (state: RootState) => state.mortgageNotes.filters
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClearAllFilters = () => {
    dispatch(
      updateFiltersAndFetch({
        filters: deserializeDates(defaultFilters),
        navigate,
      })
    );
  };

  const hasActiveFilters = () => {
    // Check array filters
    const arrayFields = [
      "addressState",
      "occupancyStatus",
      "propertyType",
      "loanType",
      "noteType",
      "lienPosition",
      "performanceStatus",
    ] as const;
    if (arrayFields.some((field) => filters[field]?.length > 0)) return true;

    // Check range filters
    const rangeFields = [
      "initialLoanAmount",
      "currentLoanAmount",
      "loanPaymentAmount",
      "totalPayoff",
      "estimatedMarketValue",
      "borrowerDownPayment",
      "askingAmount",
      "interestRate",
      "loanTerm",
      "bedrooms",
      "bathrooms",
      "squareFootage",
    ] as const;
    if (
      rangeFields.some((field) => {
        const range = filters[field];
        return range?.min !== null || range?.max !== null;
      })
    )
      return true;

    // Check date filters
    const dateFields = ["originationDate", "maturityDate"] as const;
    if (
      dateFields.some((field) => {
        const dateRange = filters[field];
        return dateRange?.start || dateRange?.end;
      })
    )
      return true;

    return false;
  };

  const renderRangeFilter = (
    field: keyof FilterState,
    label: string,
    range: { min: number | null; max: number | null } | undefined,
    formatter: (value: number) => string = (value) => value.toString()
  ) => {
    if (!range || (range.min === null && range.max === null)) return null;

    if (range.min !== null && range.max !== null) {
      return (
        <motion.button
          layout
          key={`${field}-range`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label}: {formatter(range.min)} - {formatter(range.max)}
        </motion.button>
      );
    }

    if (range.min !== null) {
      return (
        <motion.button
          layout
          key={`${field}-min`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label} ≥ {formatter(range.min)}
        </motion.button>
      );
    }

    if (range.max !== null) {
      return (
        <motion.button
          layout
          key={`${field}-max`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label} ≤ {formatter(range.max)}
        </motion.button>
      );
    }

    return null;
  };

  const renderDateFilter = (
    field: "originationDate" | "maturityDate",
    label: string,
    dateRange: { start: string | null; end: string | null } | undefined
  ) => {
    if (!dateRange || (dateRange.start === null && dateRange.end === null))
      return null;

    if (dateRange.start && dateRange.end) {
      return (
        <motion.button
          layout
          key={`${field}-range`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label}: {new Date(dateRange.start).toLocaleDateString()} -{" "}
          {new Date(dateRange.end).toLocaleDateString()}
        </motion.button>
      );
    }

    if (dateRange.start) {
      return (
        <motion.button
          layout
          key={`${field}-start`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label} From: {new Date(dateRange.start).toLocaleDateString()}
        </motion.button>
      );
    }

    if (dateRange.end) {
      return (
        <motion.button
          layout
          key={`${field}-end`}
          className={className}
          onClick={() => onRemoveFilter(field)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            layout: { duration: 0.3 },
            opacity: { duration: 0.2 },
            scale: { duration: 0.2 },
          }}
        >
          <FontAwesomeIcon icon={faXmark} className={iconClassName} />
          {label} Until: {new Date(dateRange.end).toLocaleDateString()}
        </motion.button>
      );
    }

    return null;
  };

  const renderArrayFilter = (
    field: keyof FilterState,
    label: string,
    values: string[] | undefined
  ) => {
    if (!values?.length) return null;

    return values.map((value) => (
      <motion.button
        layout
        key={`${field}-${value}`}
        className={className}
        onClick={() => onRemoveFilter(field, value)}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{
          layout: { duration: 0.3 },
          opacity: { duration: 0.2 },
          scale: { duration: 0.2 },
        }}
      >
        <FontAwesomeIcon icon={faXmark} className={iconClassName} />
        {label}: {value}
      </motion.button>
    ));
  };

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

  const formatPercentage = (value: number) => `${value}%`;

  return (
    <LayoutGroup>
      <div className="flex flex-wrap items-start gap-1.5 md:visible">
        <button
          onClick={() => setShowFilterDrawer(true)}
          className="btn btn-accent btn-sm w-fit"
        >
          <FontAwesomeIcon icon={faBarsFilter} />
          MORE FILTERS
        </button>
        <AnimatePresence>
          {hasActiveFilters() && (
            <motion.button
              layout
              className="btn btn-sm w-fit btn-error text-white"
              onClick={handleClearAllFilters}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{
                layout: { duration: 0.3 },
                opacity: { duration: 0.2 },
                scale: { duration: 0.2 },
              }}
            >
              <FontAwesomeIcon icon={faSquareXmark} className="text-white" />
              CLEAR ALL
            </motion.button>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {renderArrayFilter(
            "addressState",
            "State",
            filters.addressState?.map((s) => s.toString())
          )}
          {renderArrayFilter(
            "occupancyStatus",
            "Occupancy",
            filters.occupancyStatus?.map((s) => s.toString())
          )}
          {renderArrayFilter(
            "propertyType",
            "Property",
            filters.propertyType?.map((t) => t.toString())
          )}
          {renderArrayFilter(
            "loanType",
            "Loan Type",
            filters.loanType?.map((t) => t.toString())
          )}
          {renderArrayFilter(
            "noteType",
            "Note Type",
            filters.noteType?.map((t) => t.toString())
          )}
          {renderArrayFilter(
            "lienPosition",
            "Lien",
            filters.lienPosition?.map((p) => p.toString())
          )}
          {renderArrayFilter(
            "performanceStatus",
            "Performance",
            filters.performanceStatus?.map((s) => s.toString())
          )}

          {renderRangeFilter(
            "initialLoanAmount",
            "Initial Loan",
            filters.initialLoanAmount,
            formatCurrency
          )}
          {renderRangeFilter(
            "currentLoanAmount",
            "Current Loan",
            filters.currentLoanAmount,
            formatCurrency
          )}
          {renderRangeFilter(
            "loanPaymentAmount",
            "Payment",
            filters.loanPaymentAmount,
            formatCurrency
          )}
          {renderRangeFilter(
            "totalPayoff",
            "Payoff",
            filters.totalPayoff,
            formatCurrency
          )}
          {renderRangeFilter(
            "estimatedMarketValue",
            "Market Value",
            filters.estimatedMarketValue,
            formatCurrency
          )}
          {renderRangeFilter(
            "borrowerDownPayment",
            "Down Payment",
            filters.borrowerDownPayment,
            formatCurrency
          )}
          {renderRangeFilter(
            "askingAmount",
            "Asking",
            filters.askingAmount,
            formatCurrency
          )}
          {renderRangeFilter(
            "interestRate",
            "Interest",
            filters.interestRate,
            formatPercentage
          )}
          {renderRangeFilter("loanTerm", "Term (Months)", filters.loanTerm)}
          {renderRangeFilter("bedrooms", "Beds", filters.bedrooms)}
          {renderRangeFilter("bathrooms", "Baths", filters.bathrooms)}
          {renderRangeFilter("squareFootage", "Sq Ft", filters.squareFootage)}

          {renderDateFilter(
            "originationDate",
            "Originated",
            filters.originationDate
          )}
          {renderDateFilter("maturityDate", "Matures", filters.maturityDate)}
        </AnimatePresence>
      </div>
    </LayoutGroup>
  );
};

export default ActiveFilters;
