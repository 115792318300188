import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface InvalidProps {
  message?: string;
}

const Invalid = ({ message }: InvalidProps) => {
  return (
    <div className="  flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 w-full">
      <div className="max-w-md w-full space-y-8 text-center">
        <div>
          <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-yellow-100">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="h-12 w-12 text-warning mb-1.5"
              aria-hidden="true"
            />
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Invalid Request
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            {message || "The requested action could not be completed."}
          </p>
        </div>
        <div className="mt-8">
          <Link to="/">
            <button className="btn btn-accent cursor-pointer">RETURN HOME</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Invalid;
