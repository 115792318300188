import addCommas from "./addCommas";

const calculateMonthlyReturn = (
  loanAmount: number,
  annualInterestRate: number,
  loanTermInMonths: number
): string => {
  // Handle edge cases
  if (
    !loanAmount ||
    !annualInterestRate ||
    !loanTermInMonths ||
    loanTermInMonths === 0
  ) {
    return "-";
  }

  // Calculate the annual interest
  const annualInterest = loanAmount * (annualInterestRate / 100);

  // Calculate the monthly return
  const monthlyReturn = annualInterest / loanTermInMonths;

  // Check for infinity or NaN
  if (!isFinite(monthlyReturn) || isNaN(monthlyReturn)) {
    return "-";
  }

  // Format with exactly 2 decimal places
  const formattedNumber = monthlyReturn.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber || "-";
};

export { calculateMonthlyReturn };
