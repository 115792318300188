import { useEffect } from "react";

type UseSwipeOptions = {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  swipeThreshold?: number;
};

export const useSwipe = ({
  onSwipeLeft,
  onSwipeRight,
  swipeThreshold = 50,
}: UseSwipeOptions): void => {
  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;

    const onTouchStart = (e: TouchEvent) => {
      touchStartX = e.touches[0].clientX;
    };

    const onTouchMove = (e: TouchEvent) => {
      touchEndX = e.touches[0].clientX;
    };

    const onTouchEnd = () => {
      const distance = touchStartX - touchEndX;
      if (Math.abs(distance) > swipeThreshold) {
        if (distance > 0) {
          onSwipeLeft();
        } else {
          onSwipeRight();
        }
      }
    };

    document.addEventListener("touchstart", onTouchStart);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchEnd);

    // Cleanup
    return () => {
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    };
  }, [onSwipeLeft, onSwipeRight, swipeThreshold]);
};
