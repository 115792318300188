import { useSwipe } from "../../hooks/useSwipe";
import useOnclickOutside from "react-cool-onclickoutside";
import FilterForm from "./FilterForm";

interface FilterDrawerProps {
  showFilterDrawer: boolean;
  setShowFilterDrawer: (showFilterDrawer: boolean) => void;
  onApplyFilters: (filters: any) => void;
}

const FilterDrawer = ({
  showFilterDrawer,
  setShowFilterDrawer,
  onApplyFilters,
}: FilterDrawerProps) => {
  const ref = useOnclickOutside(() => {
    setShowFilterDrawer(false);
  });

  const backGroundColor = "rgba(0,0,0,0.6)";

  useSwipe({
    onSwipeRight: () => {},
    onSwipeLeft: () => setShowFilterDrawer(false),
    swipeThreshold: 90,
  });

  const handleSaveFilters = (filters: any) => {
    onApplyFilters(filters);
    setShowFilterDrawer(false);
  };

  return (
    <>
      <div className="drawer">
        <input
          type="checkbox"
          id="help-modal"
          checked={showFilterDrawer}
          onChange={() => {}}
          className="modal-toggle"
        />

        <label
          htmlFor="help-modal"
          className="modal flex justify-start"
          style={{
            backgroundColor: backGroundColor,
          }}
        >
          <div
            ref={ref}
            className="shadow-m mt-24 bg-card-light  text-sm font-normal text-text-dark dark:bg-card-dark dark:text-text-light relative modal-box h-full max-h-screen w-96  bg-back-light dark:bg-back-dark sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-5/12 2xl:w-4/12"
          >
            <FilterForm onApplyFilters={handleSaveFilters} />
          </div>
        </label>
      </div>
    </>
  );
};

export default FilterDrawer;
