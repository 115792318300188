import React from "react";
import { formatCurrencyInput } from "../../../utils/inputUtils";

interface OfferInputProps {
  offerAmount: string;
  setOfferAmount: (value: string) => void;
}

const OfferInput = ({ offerAmount, setOfferAmount }: OfferInputProps) => {
  const handleOfferAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value.replace(/[^\d.]/g, "");
    setOfferAmount(value);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Enter amount"
        className="input input-bordered pl-8 w-full font-bold"
        value={offerAmount ? formatCurrencyInput(offerAmount) : ""}
        onChange={handleOfferAmountChange}
      />
    </>
  );
};

export default OfferInput;
