import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import { ModalProvider } from "../contexts/ModalContext";
import { AlertProvider } from "../contexts/AlertContext";
import TokenRefreshGuard from "../auth/TokenRefreshGuard";

/***** Context Providers *****/

interface AppProviderProps {}

const ApplicationProvider = ({
  children,
}: PropsWithChildren<AppProviderProps>) => (
  <BrowserRouter>
    <AuthProvider>
      <AlertProvider>
        <ModalProvider>
          <TokenRefreshGuard>{children}</TokenRefreshGuard>
        </ModalProvider>
      </AlertProvider>
    </AuthProvider>
  </BrowserRouter>
);

export default ApplicationProvider;
