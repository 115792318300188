import React, { useRef } from "react";
import {
  faFileLines,
  faFileInvoiceDollar,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../loading/LoadingSpinner";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";

interface UploadedFile {
  file: File;
  name: string;
}

interface UploadDocumentsSlideProps {
  note: FullIncomeNote;
  goToPrevSlide: () => void;
  goToNextSlide: () => void;
  isSubmitting: boolean;
  loiFile: UploadedFile | null;
  pofFile: UploadedFile | null;
  setLoiFile: (file: UploadedFile | null) => void;
  setPofFile: (file: UploadedFile | null) => void;
  onSubmit: () => Promise<void>;
}

const UploadDocumentsSlide = ({
  note,
  goToPrevSlide,
  goToNextSlide,
  isSubmitting,
  loiFile,
  pofFile,
  setLoiFile,
  setPofFile,
  onSubmit,
}: UploadDocumentsSlideProps) => {
  const loiInputRef = useRef<HTMLInputElement>(null);
  const pofInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "loi" | "pof"
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSize) {
      alert("File size must be less than 10MB");
      return;
    }

    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!allowedTypes.includes(file.type)) {
      alert("Only PDF and Word documents are allowed");
      return;
    }

    const uploadedFile: UploadedFile = {
      file,
      name: file.name,
    };

    if (type === "loi") {
      setLoiFile(uploadedFile);
    } else {
      setPofFile(uploadedFile);
    }
  };

  const handleRemoveFile = (type: "loi" | "pof") => {
    if (type === "loi") {
      setLoiFile(null);
      if (loiInputRef.current) {
        loiInputRef.current.value = "";
      }
    } else {
      setPofFile(null);
      if (pofInputRef.current) {
        pofInputRef.current.value = "";
      }
    }
  };

  const handleExampleLOIClick = async () => {
    try {
      // First verify the file exists
      const response = await fetch("/LetterOfIntent.docx");
      if (!response.ok) {
        throw new Error(`File not found (${response.status})`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Letter of Intent.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert(
        "Sorry, there was an error downloading the file. Please try again later."
      );
    }
  };

  const FileUploadButton = ({
    type,
    file,
    inputRef,
  }: {
    type: "loi" | "pof";
    file: UploadedFile | null;
    inputRef: React.RefObject<HTMLInputElement>;
  }) => (
    <div className="w-full max-w-md">
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        accept=".pdf,.doc,.docx"
        onChange={(e) => handleFileUpload(e, type)}
      />
      {!file ? (
        <button
          onClick={() => inputRef.current?.click()}
          className="btn btn-accent w-full"
        >
          <FontAwesomeIcon
            icon={type === "loi" ? faFileLines : faFileInvoiceDollar}
            className="mr-2"
          />
          Upload {type === "loi" ? "Letter of Intent" : "Proof of Funds"}
        </button>
      ) : (
        <div className="flex items-center justify-between py-2 px-4 bg-gray-200 rounded-lg w-full">
          <div className="flex items-center gap-2 min-w-0 flex-1">
            <input
              type="checkbox"
              defaultChecked
              onChange={() => {}}
              checked={true}
              className="checkbox checkbox-accent shrink-0"
            />
            <span className="text-sm font-semibold truncate block">
              {file.name}
            </span>
          </div>
          <button
            onClick={() => handleRemoveFile(type)}
            className="btn btn-ghost btn-sm shrink-0 ml-2"
          >
            <FontAwesomeIcon icon={faTrash} className="text-gray-700" />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="w-full flex flex-col px-8 pt-20 lg:pt-4">
      <div className="flex items-center justify-center mb-1">
        <button onClick={goToPrevSlide} className="btn btn-ghost btn-sm">
          ← Back
        </button>
        <h2 className="text-2xl font-bold text-gray-800 ml-4">
          Upload Documents
        </h2>
      </div>

      <div className="flex flex-col gap-6 items-center justify-center mt-8">
        <FileUploadButton type="loi" file={loiFile} inputRef={loiInputRef} />
        <FileUploadButton type="pof" file={pofFile} inputRef={pofInputRef} />

        {loiFile && pofFile ? (
          <button
            onClick={onSubmit}
            className="btn btn-accent w-full max-w-md mt-1"
          >
            {isSubmitting ? (
              <LoadingSpinner />
            ) : (
              `${note.accept_offers ? "Next" : "Submit Offer"}`
            )}
          </button>
        ) : (
          <>
            <p className="text-xs text-gray-600 mt-2 text-center max-w-md">
              Please upload your Letter of Intent and Proof of Funds documents
              to express your interest in this note. Accepted formats: PDF, DOC,
              DOCX (Max 10MB)
              <br />
              <br />
              LOI Template:{" "}
              <button
                onClick={handleExampleLOIClick}
                className="text-brown underline"
              >
                Letter of Intent.docx
              </button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadDocumentsSlide;
