import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Alert from "../components/alert/Alert";
import useAlert from "../hooks/useAlert";
import HeaderNav from "../components/nav/HeaderNav";

interface SharedLayoutProps {
  children?: ReactNode;
}

const SharedLayout = ({ children }: SharedLayoutProps) => {
  const { alert, setAlert } = useAlert();

  return (
    <div className="relative overflow-hidden min-h-screen">
      <HeaderNav />
      <div className="absolute top-0 left-0 right-0 h-screen w-full bg-white z-0"></div>
      <div className="flex flex-col columns-1 bg-white w-full h-full items-start justify-center font-montserrat relative">
        <div
          className={
            alert.display
              ? "fixed left-0 right-0 top-[80px] z-50 mx-1.5  transition-all duration-700 flex justify-center"
              : "fixed left-0 right-0 -top-20 z-10 mx-1.5 transition-all duration-700 flex justify-center"
          }
        >
          <Alert
            alert={alert}
            onClose={() =>
              setAlert({
                display: false,
                type: alert.type,
                message: alert.message,
              })
            }
          />
        </div>
        <div className="h-24"></div>
        {children || <Outlet />}
      </div>
    </div>
  );
};

export default SharedLayout;
