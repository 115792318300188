import {
  PerformanceStatusEnum,
  OccupancyStatusEnum,
  PropertyTypeEnum,
  LienPositionEnum,
  LoanTypeEnum,
  NoteTypeEnum,
  FetchNotesParams,
} from "../redux/interfaces/mortgageNote.redux.interface";
import { FilterState } from "../components/notes/FilterForm";
import { NavigateFunction } from "react-router-dom";

// Type definitions for our mappings
type EnumMapping<T> = {
  [key: string]: T;
};

// Define all our enum mappings
const performanceStatusMapping: EnumMapping<PerformanceStatusEnum> = {
  Performing: PerformanceStatusEnum.Performing,
  UnderPerforming: PerformanceStatusEnum.UnderPerforming,
  NonPerforming: PerformanceStatusEnum.NonPerforming,
};

const occupancyStatusMapping: EnumMapping<OccupancyStatusEnum> = {
  "Owner Occupied": OccupancyStatusEnum.OwnerOccupied,
  "Non Owner Occupied": OccupancyStatusEnum.NonOwnerOccupied,
  Vacant: OccupancyStatusEnum.Vacant,
  Unknown: OccupancyStatusEnum.Unknown,
};

const propertyTypeMapping: EnumMapping<PropertyTypeEnum> = {
  "Single Family": PropertyTypeEnum.SingleFamily,
  Duplex: PropertyTypeEnum.Duplex,
  "Multi Unit 3+": PropertyTypeEnum.MultiUnit3Plus,
  Townhouse: PropertyTypeEnum.Townhouse,
  Condo: PropertyTypeEnum.Condo,
  Land: PropertyTypeEnum.Land,
  Other: PropertyTypeEnum.Other,
};

const loanTypeMapping: EnumMapping<LoanTypeEnum> = {
  "Interest Only": LoanTypeEnum.InterestOnly,
  "Amortized Loan": LoanTypeEnum.AmortizedLoan,
};

const noteTypeMapping: EnumMapping<NoteTypeEnum> = {
  Mortgage: NoteTypeEnum.Mortgage,
  "Deed of Trust": NoteTypeEnum.DeedOfTrust,
  "Contract For Deed": NoteTypeEnum.ContractForDeed,
};

const lienPositionMapping: EnumMapping<LienPositionEnum> = {
  "1st": LienPositionEnum.First,
  "2nd": LienPositionEnum.Second,
  "3rd": LienPositionEnum.Third,
};

// Helper function to map string values to enum values
const mapToEnum = <T>(values: string[], mapping: EnumMapping<T>): T[] => {
  return values.map((value) => {
    const enumValue = mapping[value];
    if (enumValue === undefined) {
      throw new Error(`Unknown value: ${value}`);
    }
    return enumValue;
  });
};

// Function to convert filters to URL parameters
export const filtersToUrlParams = (filters: FilterState): URLSearchParams => {
  const params = new URLSearchParams();

  // Helper to set number range params
  const setRangeParams = (
    prefix: string,
    range: { min: number | null; max: number | null }
  ) => {
    if (range.min !== null) params.set(`${prefix}Min`, range.min.toString());
    if (range.max !== null) params.set(`${prefix}Max`, range.max.toString());
  };

  // Helper to set array params
  const setArrayParams = (key: string, values: string[]) => {
    if (values.length > 0) params.set(key, values.join(","));
  };

  // Set all numeric range parameters
  setRangeParams("initialLoanAmount", filters.initialLoanAmount);
  setRangeParams("currentLoanAmount", filters.currentLoanAmount);
  setRangeParams("loanPaymentAmount", filters.loanPaymentAmount);
  setRangeParams("totalPayoff", filters.totalPayoff);
  setRangeParams("estimatedMarketValue", filters.estimatedMarketValue);
  setRangeParams("borrowerDownPayment", filters.borrowerDownPayment);
  setRangeParams("askingAmount", filters.askingAmount);
  setRangeParams("interestRate", filters.interestRate);
  setRangeParams("loanTerm", filters.loanTerm);
  setRangeParams("bedrooms", filters.bedrooms);
  setRangeParams("bathrooms", filters.bathrooms);
  setRangeParams("squareFootage", filters.squareFootage);

  // Set all array parameters
  setArrayParams("occupancyStatus", filters.occupancyStatus);
  setArrayParams("propertyType", filters.propertyType);
  setArrayParams("loanType", filters.loanType);
  setArrayParams("noteType", filters.noteType);
  setArrayParams("lienPosition", filters.lienPosition);
  setArrayParams("performanceStatus", filters.performanceStatus);

  // Handle date ranges
  if (filters.originationDate.start) {
    params.set(
      "originationDateStart",
      filters.originationDate.start.toISOString()
    );
  }
  if (filters.originationDate.end) {
    params.set("originationDateEnd", filters.originationDate.end.toISOString());
  }
  if (filters.maturityDate.start) {
    params.set("maturityDateStart", filters.maturityDate.start.toISOString());
  }
  if (filters.maturityDate.end) {
    params.set("maturityDateEnd", filters.maturityDate.end.toISOString());
  }

  return params;
};

// Function to convert URL parameters to filters
export const urlParamsToFilters = (
  searchParams: URLSearchParams
): Partial<FetchNotesParams> => {
  const filters: Partial<FetchNotesParams> = {};

  // Helper to parse number range params
  const parseRangeParams = (prefix: string) => {
    const min = searchParams.get(`${prefix}Min`);
    const max = searchParams.get(`${prefix}Max`);
    return {
      min: min ? Number(min) : null,
      max: max ? Number(max) : null,
    };
  };

  // Helper to parse array params
  const parseArrayParams = (key: string) => {
    const value = searchParams.get(key);
    return value ? value.split(",") : [];
  };

  // Parse addressState
  const addressState = parseArrayParams("addressState");
  if (addressState.length) {
    filters.addressState = addressState;
  }

  // Parse all numeric range parameters
  filters.initialLoanAmount = parseRangeParams("initialLoanAmount");
  filters.currentLoanAmount = parseRangeParams("currentLoanAmount");
  filters.loanPaymentAmount = parseRangeParams("loanPaymentAmount");
  filters.totalPayoff = parseRangeParams("totalPayoff");
  filters.estimatedMarketValue = parseRangeParams("estimatedMarketValue");
  filters.borrowerDownPayment = parseRangeParams("borrowerDownPayment");
  filters.askingAmount = parseRangeParams("askingAmount");
  filters.interestRate = parseRangeParams("interestRate");
  filters.loanTerm = parseRangeParams("loanTerm");
  filters.bedrooms = parseRangeParams("bedrooms");
  filters.bathrooms = parseRangeParams("bathrooms");
  filters.squareFootage = parseRangeParams("squareFootage");

  // Parse and map enum arrays
  const occupancyStatus = parseArrayParams("occupancyStatus");
  const propertyType = parseArrayParams("propertyType");
  const loanType = parseArrayParams("loanType");
  const noteType = parseArrayParams("noteType");
  const lienPosition = parseArrayParams("lienPosition");
  const performanceStatus = parseArrayParams("performanceStatus");

  if (occupancyStatus.length)
    filters.occupancyStatus = mapToEnum(
      occupancyStatus,
      occupancyStatusMapping
    );
  if (propertyType.length)
    filters.propertyType = mapToEnum(propertyType, propertyTypeMapping);
  if (loanType.length) filters.loanType = mapToEnum(loanType, loanTypeMapping);
  if (noteType.length) filters.noteType = mapToEnum(noteType, noteTypeMapping);
  if (lienPosition.length)
    filters.lienPosition = mapToEnum(lienPosition, lienPositionMapping);
  if (performanceStatus.length)
    filters.performanceStatus = mapToEnum(
      performanceStatus,
      performanceStatusMapping
    );

  // Parse date ranges
  const originationDateStart = searchParams.get("originationDateStart");
  const originationDateEnd = searchParams.get("originationDateEnd");
  const maturityDateStart = searchParams.get("maturityDateStart");
  const maturityDateEnd = searchParams.get("maturityDateEnd");

  if (originationDateStart || originationDateEnd) {
    filters.originationDate = {
      start: originationDateStart ? new Date(originationDateStart) : null,
      end: originationDateEnd ? new Date(originationDateEnd) : null,
    };
  }

  if (maturityDateStart || maturityDateEnd) {
    filters.maturityDate = {
      start: maturityDateStart ? new Date(maturityDateStart) : null,
      end: maturityDateEnd ? new Date(maturityDateEnd) : null,
    };
  }

  return filters;
};

// Function to map filter values to their corresponding enums
export const mapFiltersToEnums = (
  filters: FilterState
): Partial<FetchNotesParams> => {
  const mappedFilters = {
    ...filters,
    occupancyStatus: mapToEnum(filters.occupancyStatus, occupancyStatusMapping),
    performanceStatus: mapToEnum(
      filters.performanceStatus,
      performanceStatusMapping
    ),
    propertyType: mapToEnum(filters.propertyType, propertyTypeMapping),
    loanType: mapToEnum(filters.loanType, loanTypeMapping),
    noteType: mapToEnum(filters.noteType, noteTypeMapping),
    lienPosition: mapToEnum(filters.lienPosition, lienPositionMapping),
  };

  // Keep Date objects as they are for the Redux store
  if (filters.originationDate.start || filters.originationDate.end) {
    mappedFilters.originationDate = {
      start: filters.originationDate.start,
      end: filters.originationDate.end,
    };
  }

  if (filters.maturityDate.start || filters.maturityDate.end) {
    mappedFilters.maturityDate = {
      start: filters.maturityDate.start,
      end: filters.maturityDate.end,
    };
  }

  return mappedFilters;
};

// Function to update URL and return mapped filters
export const processFilters = (
  filters: FilterState,
  navigate: NavigateFunction
): Partial<FetchNotesParams> => {
  const params = filtersToUrlParams(filters);
  navigate({ search: params.toString() });
  return mapFiltersToEnums(filters);
};
