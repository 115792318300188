import React from "react";
import { FullMortgageNote } from "../../../interfaces/mortgageNote.interface";
import MainDetailsSlide from "./MainDetailsSlide";

interface MortgageNoteDetailsProps {
  note: FullMortgageNote;
}

const MortgageNoteDetails = ({ note }: MortgageNoteDetailsProps) => {
  return (
    <>
      <MainDetailsSlide note={note} />
    </>
  );
};

export default MortgageNoteDetails;
