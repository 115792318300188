import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PerformanceStatusEnum,
  OccupancyStatusEnum,
  PropertyTypeEnum,
  LienPositionEnum,
  LoanTypeEnum,
  NoteTypeEnum,
} from "../../redux/interfaces/mortgageNote.redux.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faRotate,
  faSquareXmark,
} from "@fortawesome/pro-solid-svg-icons";

interface FilterFormProps {
  onApplyFilters: (filters: FilterState) => void;
}

export interface FilterState {
  addressState: string[];
  occupancyStatus: string[];
  propertyType: string[];
  loanType: string[];
  noteType: string[];
  lienPosition: string[];
  performanceStatus: string[];
  initialLoanAmount: { min: number | null; max: number | null };
  currentLoanAmount: { min: number | null; max: number | null };
  loanPaymentAmount: { min: number | null; max: number | null };
  totalPayoff: { min: number | null; max: number | null };
  estimatedMarketValue: { min: number | null; max: number | null };
  borrowerDownPayment: { min: number | null; max: number | null };
  askingAmount: { min: number | null; max: number | null };
  interestRate: { min: number | null; max: number | null };
  loanTerm: { min: number | null; max: number | null };
  bedrooms: { min: number | null; max: number | null };
  bathrooms: { min: number | null; max: number | null };
  squareFootage: { min: number | null; max: number | null };
  originationDate: { start: Date | null; end: Date | null };
  maturityDate: { start: Date | null; end: Date | null };
}

const OCCUPANCY_STATUS = [
  OccupancyStatusEnum.OwnerOccupied,
  OccupancyStatusEnum.NonOwnerOccupied,
  OccupancyStatusEnum.Vacant,
  OccupancyStatusEnum.Unknown,
] as const;

const PROPERTY_TYPE = [
  PropertyTypeEnum.SingleFamily,
  PropertyTypeEnum.Duplex,
  PropertyTypeEnum.MultiUnit3Plus,
  PropertyTypeEnum.Townhouse,
  PropertyTypeEnum.Condo,
  PropertyTypeEnum.Land,
  PropertyTypeEnum.Other,
] as const;

const LOAN_TYPE = [
  LoanTypeEnum.InterestOnly,
  LoanTypeEnum.AmortizedLoan,
] as const;

const NOTE_TYPE = [
  NoteTypeEnum.Mortgage,
  NoteTypeEnum.DeedOfTrust,
  NoteTypeEnum.ContractForDeed,
] as const;

const LIEN_POSITION = [
  LienPositionEnum.First,
  LienPositionEnum.Second,
  LienPositionEnum.Third,
] as const;

const PERFORMANCE_STATUS = [
  PerformanceStatusEnum.Performing,
  PerformanceStatusEnum.UnderPerforming,
  PerformanceStatusEnum.NonPerforming,
] as const;

const defaultFilters: FilterState = {
  addressState: [],
  occupancyStatus: [],
  propertyType: [],
  loanType: [],
  noteType: [],
  lienPosition: [],
  performanceStatus: [],
  initialLoanAmount: { min: null, max: null },
  currentLoanAmount: { min: null, max: null },
  loanPaymentAmount: { min: null, max: null },
  totalPayoff: { min: null, max: null },
  estimatedMarketValue: { min: null, max: null },
  borrowerDownPayment: { min: null, max: null },
  askingAmount: { min: null, max: null },
  interestRate: { min: null, max: null },
  loanTerm: { min: null, max: null },
  bedrooms: { min: null, max: null },
  bathrooms: { min: null, max: null },
  squareFootage: { min: null, max: null },
  originationDate: { start: null, end: null },
  maturityDate: { start: null, end: null },
};

const FilterForm: React.FC<FilterFormProps> = ({ onApplyFilters }) => {
  const reduxFilters = useSelector(
    (state: RootState) => state.mortgageNotes.filters
  );
  const [filters, setFilters] = useState<FilterState>(defaultFilters);
  const [initialFilters, setInitialFilters] =
    useState<FilterState>(defaultFilters);

  useEffect(() => {
    const convertedFilters: FilterState = {
      ...defaultFilters,
      addressState: reduxFilters.addressState || [],
      occupancyStatus:
        reduxFilters.occupancyStatus?.map((status) => status.toString()) || [],
      propertyType:
        reduxFilters.propertyType?.map((type) => type.toString()) || [],
      loanType: reduxFilters.loanType?.map((type) => type.toString()) || [],
      noteType: reduxFilters.noteType?.map((type) => type.toString()) || [],
      lienPosition:
        reduxFilters.lienPosition?.map((pos) => pos.toString()) || [],
      performanceStatus:
        reduxFilters.performanceStatus?.map((status) => status.toString()) ||
        [],
      initialLoanAmount: reduxFilters.initialLoanAmount || {
        min: null,
        max: null,
      },
      currentLoanAmount: reduxFilters.currentLoanAmount || {
        min: null,
        max: null,
      },
      loanPaymentAmount: reduxFilters.loanPaymentAmount || {
        min: null,
        max: null,
      },
      totalPayoff: reduxFilters.totalPayoff || { min: null, max: null },
      estimatedMarketValue: reduxFilters.estimatedMarketValue || {
        min: null,
        max: null,
      },
      borrowerDownPayment: reduxFilters.borrowerDownPayment || {
        min: null,
        max: null,
      },
      askingAmount: reduxFilters.askingAmount || { min: null, max: null },
      interestRate: reduxFilters.interestRate || { min: null, max: null },
      loanTerm: reduxFilters.loanTerm || { min: null, max: null },
      bedrooms: reduxFilters.bedrooms || { min: null, max: null },
      bathrooms: reduxFilters.bathrooms || { min: null, max: null },
      squareFootage: reduxFilters.squareFootage || { min: null, max: null },
      originationDate: {
        start: reduxFilters.originationDate?.start
          ? new Date(reduxFilters.originationDate.start)
          : null,
        end: reduxFilters.originationDate?.end
          ? new Date(reduxFilters.originationDate.end)
          : null,
      },
      maturityDate: {
        start: reduxFilters.maturityDate?.start
          ? new Date(reduxFilters.maturityDate.start)
          : null,
        end: reduxFilters.maturityDate?.end
          ? new Date(reduxFilters.maturityDate.end)
          : null,
      },
    };
    setFilters(convertedFilters);
    setInitialFilters(convertedFilters);
  }, [reduxFilters]);

  const hasChanges = () => {
    // Compare arrays
    const arrayFields: (keyof FilterState)[] = [
      "addressState",
      "occupancyStatus",
      "propertyType",
      "loanType",
      "noteType",
      "lienPosition",
      "performanceStatus",
    ];

    const arraysChanged = arrayFields.some(
      (field) =>
        JSON.stringify(filters[field]) !== JSON.stringify(initialFilters[field])
    );
    if (arraysChanged) return true;

    // Compare ranges
    const rangeFields: (keyof FilterState)[] = [
      "initialLoanAmount",
      "currentLoanAmount",
      "loanPaymentAmount",
      "totalPayoff",
      "estimatedMarketValue",
      "borrowerDownPayment",
      "askingAmount",
      "interestRate",
      "loanTerm",
      "bedrooms",
      "bathrooms",
      "squareFootage",
    ];

    const rangesChanged = rangeFields.some(
      (field) =>
        JSON.stringify(filters[field]) !== JSON.stringify(initialFilters[field])
    );
    if (rangesChanged) return true;

    // Compare dates
    const dateFields = ["originationDate", "maturityDate"] as const;
    const datesChanged = dateFields.some((field) => {
      const currentDates = filters[field] as {
        start: Date | null;
        end: Date | null;
      };
      const initialDates = initialFilters[field] as {
        start: Date | null;
        end: Date | null;
      };
      return (
        currentDates.start?.getTime() !== initialDates.start?.getTime() ||
        currentDates.end?.getTime() !== initialDates.end?.getTime()
      );
    });

    return datesChanged;
  };

  const handleRangeChange = (
    field: keyof FilterState,
    type: "min" | "max" | "start" | "end",
    value: string
  ) => {
    const numValue = value === "" ? null : Number(value);
    setFilters((prev) => ({
      ...prev,
      [field]: {
        ...prev[field as keyof typeof prev],
        [type]: numValue,
      },
    }));
  };

  const handleDateChange = (
    field: "originationDate" | "maturityDate",
    type: "start" | "end",
    value: string
  ) => {
    setFilters((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [type]: value ? new Date(value) : null,
      },
    }));
  };

  const handleEnumToggle = (field: keyof FilterState, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[field] as string[];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];
      return {
        ...prev,
        [field]: newValues,
      };
    });
  };

  const handleReset = () => {
    setFilters(defaultFilters);
    onApplyFilters(defaultFilters);
  };

  const renderRangeInputs = (
    field: keyof FilterState,
    label: string,
    step: string = "1",
    min?: string,
    max?: string
  ) => {
    const value = filters[field];
    if (!value || typeof value !== "object" || !("min" in value)) {
      return null;
    }

    return (
      <div className="filter-section">
        <h3 className="text-lg font-semibold mb-2">{label}</h3>
        <div className="flex gap-4">
          <input
            type="number"
            placeholder="Min"
            value={value.min || ""}
            onChange={(e) => handleRangeChange(field, "min", e.target.value)}
            className="input input-bordered w-full"
            step={step}
            min={min}
            max={max}
          />
          <input
            type="number"
            placeholder="Max"
            value={value.max || ""}
            onChange={(e) => handleRangeChange(field, "max", e.target.value)}
            className="input input-bordered w-full"
            step={step}
            min={min}
            max={max}
          />
        </div>
      </div>
    );
  };

  const renderDateRangeInputs = (
    field: "originationDate" | "maturityDate",
    label: string
  ) => (
    <div className="filter-section">
      <h3 className="text-lg font-semibold mb-2">{label}</h3>
      <div className="flex gap-4">
        <input
          type="date"
          value={filters[field].start?.toISOString().split("T")[0] || ""}
          onChange={(e) => handleDateChange(field, "start", e.target.value)}
          className="input input-bordered w-full"
        />
        <input
          type="date"
          value={filters[field].end?.toISOString().split("T")[0] || ""}
          onChange={(e) => handleDateChange(field, "end", e.target.value)}
          className="input input-bordered w-full"
        />
      </div>
    </div>
  );

  const renderEnumCheckboxes = (
    field: keyof FilterState,
    label: string,
    options: readonly string[]
  ) => (
    <div className="filter-section">
      <h3 className="text-lg font-semibold mb-2">{label}</h3>
      <div className="flex flex-wrap gap-2">
        {options.map((value) => (
          <label key={value} className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={(filters[field] as string[]).includes(value)}
              onChange={() => handleEnumToggle(field, value)}
              className="checkbox checkbox-secondary checked:checkbox-accent"
            />
            <span>{value}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const handleApplyFilters = () => {
    if (hasChanges()) {
      onApplyFilters(filters);
    }
  };

  return (
    <div className="flex flex-col gap-6 overflow-y-auto max-h-[calc(100vh-100px)] px-1">
      {/* Enum Filters */}
      {renderEnumCheckboxes(
        "occupancyStatus",
        "Occupancy Status",
        OCCUPANCY_STATUS
      )}
      {renderEnumCheckboxes("propertyType", "Property Type", PROPERTY_TYPE)}
      {renderEnumCheckboxes("loanType", "Loan Type", LOAN_TYPE)}
      {renderEnumCheckboxes("noteType", "Note Type", NOTE_TYPE)}
      {renderEnumCheckboxes("lienPosition", "Lien Position", LIEN_POSITION)}
      {renderEnumCheckboxes(
        "performanceStatus",
        "Performance Status",
        PERFORMANCE_STATUS
      )}

      {/* Date Ranges */}
      {renderDateRangeInputs("originationDate", "Origination Date")}
      {renderDateRangeInputs("maturityDate", "Maturity Date")}

      {/* Monetary Ranges */}
      {renderRangeInputs("initialLoanAmount", "Initial Loan Amount", "0.01")}
      {renderRangeInputs("currentLoanAmount", "Current Loan Amount", "0.01")}
      {renderRangeInputs("loanPaymentAmount", "Loan Payment Amount", "0.01")}
      {renderRangeInputs("totalPayoff", "Total Payoff", "0.01")}
      {renderRangeInputs(
        "estimatedMarketValue",
        "Estimated Market Value",
        "0.01"
      )}
      {renderRangeInputs(
        "borrowerDownPayment",
        "Borrower Down Payment",
        "0.01"
      )}
      {renderRangeInputs("askingAmount", "Asking Amount", "0.01")}
      {renderRangeInputs(
        "interestRate",
        "Interest Rate (%)",
        "0.01",
        "0",
        "100"
      )}
      {renderRangeInputs("loanTerm", "Loan Term (Months)", "1")}

      {/* Property Details */}
      {renderRangeInputs("bedrooms", "Bedrooms", "1")}
      {renderRangeInputs("bathrooms", "Bathrooms", "0.5")}
      {renderRangeInputs("squareFootage", "Square Footage", "1")}

      <div className="h-[100px]"></div>
      <div className="h-[100px]"></div>
      <div className="fixed bottom-[60px] left-0 right-0 px-7 w-full flex justify-end gap-1 bg-card-light pt-2">
        <button
          onClick={handleReset}
          className="btn btn-error w-1/2 text-white disabled:bg-gray-300 disabled:text-gray-500"
          disabled={
            filters.addressState.length === 0 &&
            filters.occupancyStatus.length === 0 &&
            filters.propertyType.length === 0 &&
            filters.loanType.length === 0 &&
            filters.noteType.length === 0 &&
            filters.lienPosition.length === 0 &&
            filters.performanceStatus.length === 0 &&
            filters.initialLoanAmount.min === null &&
            filters.initialLoanAmount.max === null &&
            filters.currentLoanAmount.min === null &&
            filters.currentLoanAmount.max === null &&
            filters.loanPaymentAmount.min === null &&
            filters.loanPaymentAmount.max === null &&
            filters.totalPayoff.min === null &&
            filters.totalPayoff.max === null &&
            filters.estimatedMarketValue.min === null &&
            filters.estimatedMarketValue.max === null &&
            filters.borrowerDownPayment.min === null &&
            filters.borrowerDownPayment.max === null &&
            filters.askingAmount.min === null &&
            filters.askingAmount.max === null &&
            filters.interestRate.min === null &&
            filters.interestRate.max === null &&
            filters.loanTerm.min === null &&
            filters.loanTerm.max === null &&
            filters.bedrooms.min === null &&
            filters.bedrooms.max === null &&
            filters.bathrooms.min === null &&
            filters.bathrooms.max === null &&
            filters.squareFootage.min === null &&
            filters.squareFootage.max === null &&
            filters.originationDate.start === null &&
            filters.originationDate.end === null &&
            filters.maturityDate.start === null &&
            filters.maturityDate.end === null
            
          }
        >
          <FontAwesomeIcon icon={faSquareXmark} />
          CLEAR ALL
        </button>
        <button
          onClick={handleApplyFilters}
          className="btn btn-accent w-1/2 disabled:bg-gray-300 disabled:text-gray-500"
          disabled={!hasChanges()}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
          APPLY
        </button>
      </div>
    </div>
  );
};

export default FilterForm;
