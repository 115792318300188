import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ActionButtonProps {
  onClick: () => void;
  label: string;
  icon?: IconProp;
}

const ActionButton = ({ onClick, label, icon }: ActionButtonProps) => {
  return (
    <>
      <button
        onClick={onClick}
        className="bg-accent text-brown font-bold py-2 px-4 rounded w-full flex items-center justify-center gap-2"
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {label}
      </button>
    </>
  );
};

export default ActionButton;
