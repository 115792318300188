import React from "react";
import { FullIncomeNote } from "../../interfaces/incomeNote.interface";
import useModal from "../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SellerDetailsModal from "../modals/SellerDetailsModal/SellerDetailsModal";
import LoginRegisterModal from "../modals/LoginRegisterModal/LoginRegisterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faArrowLeft,
  faFile,
  faAddressCard,
} from "@fortawesome/pro-solid-svg-icons";
import ActionButton from "../buttons/ActionButton";
import NoteInfoCard from "../note/NoteInfoCard";
import PropertyImageCarousel from "../note/PropertyImageCarousel";
import SavedNoteButton from "../buttons/SavedNoteButton";
import DeleteNoteButton from "../buttons/DeleteNoteButton";
import { delay } from "../../utils/delay";

interface IncomeNotePageProps {
  incomeNote: FullIncomeNote;
  fetchIncomeNote: () => Promise<void>;
}

const IncomeNotePage = ({
  incomeNote,
  fetchIncomeNote,
}: IncomeNotePageProps) => {
  const { openModalWith } = useModal();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    occupancy_status,
    property_type,
    loan_type,
    note_type,
    lien_position,
    origination_date,
    performance_status,
    initial_loan_amount,
    current_loan_amount,
    loan_payment_amount,
    total_payoff,
    estimated_market_value,
    borrower_down_payment,
    interest_rate,
    loan_term,
    maturity_date,
    asking_amount,
    seller_comments,
    house_number,
    pre_direction,
    street,
    suffix,
    post_direction,
    unit_number,
    unit_type,
    city,
    state,
    zip,
    bedrooms,
    bathrooms,
    square_footage,
    images,
    documents,
    user,
  } = incomeNote;

  const buildAddress = (): string => {
    if (!currentUser) {
      return `${city}, ${state}`;
    }

    const parts = [
      house_number,
      pre_direction,
      street,
      suffix,
      post_direction,
    ].filter(Boolean);

    const streetAddress = parts.join(" ");

    const unit = [unit_type, unit_number].filter(Boolean).join(" ");

    const fullAddress = [streetAddress, unit, city, state, zip]
      .filter(Boolean)
      .join(", ");

    return fullAddress;
  };

  const handleDownloadClick = () => {
    openModalWith({
      title: "",
      titleImageBackground: user.banner_image ? user.banner_image : undefined,
      profileImage: user.profile_image ? user.profile_image : undefined,
      body: (
        <SellerDetailsModal note={incomeNote} fetchNote={fetchIncomeNote} />
      ),
      hideButtons: true,
    });
  };

  const handleRegister = () => {
    openModalWith({
      title: "",
      body: (
        <LoginRegisterModal
          initialSlide={1}
          note={incomeNote}
          fetchNote={fetchIncomeNote}
        />
      ),
      hideButtons: true,
    });
  };

  const formatDate = (dateString: Date | string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const addCommas = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const overviewRows = [
    { label: "LOAN TYPE", value: loan_type },
    { label: "NOTE TYPE", value: note_type },
    { label: "LIEN POSITION", value: lien_position },
    { label: "PERFORMANCE STATUS", value: performance_status },
    {
      label: "INITIAL LOAN AMOUNT",
      value: `$${addCommas(initial_loan_amount)}`,
    },
    {
      label: "CURRENT LOAN AMOUNT",
      value: `$${addCommas(current_loan_amount)}`,
    },
    {
      label: "LOAN PAYMENT AMOUNT",
      value: `$${addCommas(loan_payment_amount)}`,
    },
    { label: "TOTAL PAYOFF", value: `$${addCommas(total_payoff)}` },
    { label: "MARKET VALUE", value: `$${addCommas(estimated_market_value)}` },
    { label: "DOWN PAYMENT", value: `$${addCommas(borrower_down_payment)}` },
    { label: "LOAN TERM", value: `${loan_term} Months` },
    { label: "INTEREST RATE", value: `${interest_rate}%` },
    // { label: "ASKING AMOUNT", value: `$${addCommas(asking_amount)}` },
    { label: "ORIGINATION DATE", value: formatDate(origination_date) },
    { label: "MATURITY DATE", value: formatDate(maturity_date) },
    { label: "OCCUPANCY STATUS", value: occupancy_status },
  ];

  const addressRows = [
    { label: "ADDRESS", value: buildAddress() },
    { label: "PROPERTY TYPE", value: property_type },
    { label: "BEDROOMS", value: bedrooms },
    { label: "BATHROOMS", value: bathrooms },
    { label: "SQUARE FOOTAGE", value: addCommas(square_footage) },
  ];

  const handleDownloadDocuments = async () => {
    for (const doc of documents) {
      window.open(doc.url, "_blank");
      await delay(1000);
    }
  };

  return (
    <div className="h-full w-full px-4 text-xs xs:text-sm md:text-base">
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-full md:w-1/2">
          <div className="text-brown flex gap-1.5 items-center justify-center text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold mb-3">
            <FontAwesomeIcon icon={faLocationDot} className="" />
            <span>{buildAddress()}</span>
          </div>
          <div className="mb-3">
            <PropertyImageCarousel images={images} />
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="">
              <ActionButton
                onClick={currentUser ? handleDownloadClick : handleRegister}
                label={"SEE SELLER DETAILS"}
                icon={faAddressCard}
              />
            </div>
            {documents.length > 0 && (
              <div>
                <ActionButton
                  onClick={handleDownloadDocuments}
                  label={`VIEW DOCUMENT${documents.length > 1 ? "S" : ""}`}
                  icon={faFile}
                />
              </div>
            )}
            {currentUser && currentUser.id === user.id ? (
              <div className="mr-2">
                <DeleteNoteButton incomeNote={incomeNote} />
              </div>
            ) : (
              <div className="mr-2">
                <SavedNoteButton note={incomeNote} />
              </div>
            )}
          </div>
          {seller_comments && (
            <div className="mb-3">
              <NoteInfoCard header={"SELLER COMMENTS"}>
                <div className="px-4 mt-4">
                  <p className="text-main-gray">{seller_comments}</p>
                </div>
              </NoteInfoCard>
            </div>
          )}
          <div className="mb-3">
            <NoteInfoCard header={"OVERVIEW"}>
              <div className="w-full">
                <table className="w-full mt-3 border">
                  <tbody>
                    {overviewRows.map((row, index) => (
                      <tr key={`overview-${index}`} className="border-b">
                        <td className="text-main-gray font-medium py-2 px-1 border-r pl-3">
                          {row.label}
                        </td>
                        <td className="text-main-gray font-medium py-2 pl-3">
                          {row.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </NoteInfoCard>
          </div>
          <div className="mb-3">
            <NoteInfoCard header={"ABOUT THE PROPERTY"}>
              <div className="w-full pt-4">
                <div className="">
                  <iframe
                    title="Property Map"
                    width="100%"
                    height="300px"
                    src={`https://maps.google.com/maps?q=${encodeURIComponent(
                      buildAddress()
                    )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                  ></iframe>
                </div>
                <div className="font-bold text-main-gray my-2">Details</div>
                <table className="w-full mt-1 border">
                  <tbody>
                    {addressRows.map((row, index) => (
                      <tr key={`address-${index}`} className="border-b">
                        <td className="text-main-gray font-medium py-2 px-1 border-r pl-3">
                          {row.label}
                        </td>
                        <td className="text-main-gray font-medium py-2 pl-3">
                          {row.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </NoteInfoCard>
            <div className="mt-4">
              <div
                className="flex justify-start items-center gap-2 cursor-pointer"
                onClick={() => navigate("/")}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-main-gray text-xl"
                />
                <span className="text-main-gray font-bold text-lg">
                  BACK TO NOTES
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeNotePage;
