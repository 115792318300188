import {
  PropsWithChildren,
  createContext,
  ReactNode,
  useState,
  HTMLAttributes,
} from "react";
import ModalCard from "../components/modals/ModalCard";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ModalOptionsProps {
  title: string;
  titleImageBackground?: string;
  profileImage?: string;
  body: ReactNode;
  onSubmit?: any;
  submitLabel?: string;
  cancelLabel?: string;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  disableOutsideClick?: boolean;
  submitIcon?: IconProp;
  submitColor?: string;
  submitTextColor?: string;
  submitLoading?: boolean;
  onCancel?: () => void;
  modalStyle?: HTMLAttributes<HTMLDivElement>["style"];
  url?: {
    key: string;
    value: string;
  };
}

interface ModalContextValue {
  showModal: boolean;
  setShowModal: (showCard: boolean) => void;
  openModalWith: (o: ModalOptionsProps) => void;
  getModalOptions: () => ModalOptionsProps | null;
}

const defaultState = {
  showModal: false,
  setShowModal: () => {},
  openModalWith: () => {},
  getModalOptions: () => null,
};

export const ModalContext = createContext<ModalContextValue>({
  ...defaultState,
});

interface ModalContextProps {}

export const ModalProvider = ({
  children,
}: PropsWithChildren<ModalContextProps>) => {
  const [showModal, setShowModal] = useState<boolean>(defaultState.showModal);

  const [modalProps, setModalProps] = useState<ModalOptionsProps>({
    title: "",
    body: <></>,
  });

  const openModalWith = (o: ModalOptionsProps) => {
    setModalProps(o);
    setShowModal(true);
    o.url && window.history.pushState({}, "", `?${o.url.key}=${o.url.value}`);
  };

  const getModalOptions = () => modalProps;

  return (
    <ModalContext.Provider
      value={{ showModal, setShowModal, openModalWith, getModalOptions }}
    >
      {children}
      <ModalCard {...modalProps} />
    </ModalContext.Provider>
  );
};
