import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullMortgageNote } from "../../../interfaces/mortgageNote.interface";
import { FullIncomeNote } from "../../../interfaces/incomeNote.interface";
import useAuth from "../../../hooks/useAuth";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import {
  faCheck,
  faEnvelope,
  faFileUpload,
  faPhone,
} from "@fortawesome/pro-solid-svg-icons";

interface MainDetailsSlideProps {
  note: FullMortgageNote | FullIncomeNote;
  goToNextSlide?: () => void;
}

const MainDetailsSlide = ({ note, goToNextSlide }: MainDetailsSlideProps) => {
  const { currentUser } = useAuth();

  const {
    first_name,
    last_name,
    contact_email,
    phone,
    company,
    display_company,
    display_email,
    display_phone,
    display_name,
  } = note.user;

  const getAddress = (note: FullMortgageNote | FullIncomeNote) => {
    if ("loan_number" in note) {
      // Mortgage Note
      return {
        street_number: note.street_number,
        city: note.city,
        state: note.state,
        postal_code: note.postal_code,
      };
    } else {
      // Income Note
      return {
        street_number: note.house_number,
        city: note.city,
        state: note.state,
        postal_code: note.zip,
      };
    }
  };

  const { street_number, city, state, postal_code } = getAddress(note);

  const sellerDetails: SellerDetails = {
    ...(display_name && { seller: `${first_name} ${last_name}` }),
    ...(display_company && { company }),
    ...(display_phone && { phone: formatPhoneNumber(phone) }),
    ...(display_email && { email: contact_email }),
  };

  const buildAddress = (): string =>
    `${street_number} ${city}, ${state} ${postal_code}`;

  interface SellerDetails {
    seller?: string;
    company?: string;
    phone?: string;
    email?: string;
  }

  const emailLink = `mailto:${
    sellerDetails.email
  }?subject=I am interested in the Mortgage Note on ${buildAddress()}`;

  const phoneLink = `tel:${sellerDetails.phone}`;

  return (
    <div className="w-full flex flex-col">
      {/* Company and Action Buttons */}
      <div className="px-8 pt-20 lg:pt-4">
        {display_company && company && (
          <h2 className="text-2xl font-bold text-gray-800 mb-4">{company}</h2>
        )}
        <div className="flex gap-4 mb-6 justify-center flex-col sm:flex-row">
          {display_phone && phone && (
            <a
              href={phoneLink}
              className="btn btn-accent"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              CALL
            </a>
          )}
          {display_email && contact_email && (
            <a
              href={emailLink}
              className="btn btn-accent"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              EMAIL
            </a>
          )}
          {"loan_number" in note ? null : (
            <>
              {currentUser?.id !== note.user.id && (
                <>
                  {!note.is_interested ? (
                    <button onClick={goToNextSlide} className="btn btn-accent">
                      <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
                      I'M INTERESTED
                    </button>
                  ) : (
                    <button className="btn border-none bg-gray-100 hover:bg-gray-100 flex items-center gap-2">
                      <div className="w-6 h-6 bg-accent rounded-full flex items-center justify-center">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-black text-sm "
                        />
                      </div>
                      {!("loan_number" in note)
                        ? note.accept_offers
                          ? "OFFER SUBMITTED"
                          : "DOCS SUBMITTED"
                        : "SUBMISSION RECEIVED"}
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {"loan_number" in note ? null : (
          <div className="sm:pb-12 md:mt-12 italic text-center text-xs sm:text-sm ">
            <p>{note?.terms}</p>
          </div>
        )}
      </div>

    </div>
  );
};

export default MainDetailsSlide;
