import { TOKEN_ID } from "../contexts/AuthContext";

export default class ApiTokenSupport {
  protected withAuthorization(
    headers?: Record<string, string>,
    token?: string
  ) {
    return {
      headers: {
        ...(headers || {}),
        Authorization: `Bearer ${token || localStorage.getItem(TOKEN_ID)}`,
      },
    };
  }
}
