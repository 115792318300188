import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UnitedStatesState } from "../../data/unitedStates";
import capitalize from "../../helpers/capitalize";
import states from "../../data/unitedStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import FilterValueRange, { Option } from "./FilterValueRange";
import { AppDispatch, RootState } from "../../redux/store";
import { updateFiltersAndFetch } from "../../redux/slices/mortgageNotesSlice";
import { useNavigate } from "react-router-dom";
import { PerformanceStatusEnum } from "../../redux/interfaces/mortgageNote.redux.interface";

const FilterToolbar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const filters = useSelector(
    (state: RootState) => state.mortgageNotes.filters
  );

  const [selectedStates, setSelectedStates] = useState<string[]>(
    filters.addressState || []
  );
  const [selectedPerformanceStatus, setSelectedPerformanceStatus] = useState<
    PerformanceStatusEnum[]
  >(filters.performanceStatus || []);
  const [interestRate, setInterestRate] = useState<
    [number | undefined, number | undefined]
  >([
    filters.interestRate?.min || undefined,
    filters.interestRate?.max || undefined,
  ]);
  const [currentLoanAmount, setCurrentLoanAmount] = useState<
    [number | undefined, number | undefined]
  >([
    filters.currentLoanAmount?.min || undefined,
    filters.currentLoanAmount?.max || undefined,
  ]);

  useEffect(() => {
    setSelectedStates(filters.addressState || []);
    setSelectedPerformanceStatus(filters.performanceStatus || []);
    setInterestRate([
      filters.interestRate?.min || undefined,
      filters.interestRate?.max || undefined,
    ]);
    setCurrentLoanAmount([
      filters.currentLoanAmount?.min || undefined,
      filters.currentLoanAmount?.max || undefined,
    ]);
  }, [filters]);

  const handleStateFilter = useCallback(
    (state: string) => {
      const updatedStates = selectedStates.includes(state)
        ? selectedStates.filter((s) => s !== state)
        : [...selectedStates, state];
      setSelectedStates(updatedStates);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            addressState: updatedStates.length > 0 ? updatedStates : undefined,
          },
          navigate,
        })
      );
    },
    [selectedStates, dispatch, navigate]
  );

  const handlePerformanceStatusFilter = useCallback(
    (status: PerformanceStatusEnum) => {
      const updatedStatus = selectedPerformanceStatus.includes(status)
        ? selectedPerformanceStatus.filter((s) => s !== status)
        : [...selectedPerformanceStatus, status];
      setSelectedPerformanceStatus(updatedStatus);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            performanceStatus:
              updatedStatus.length > 0 ? updatedStatus : undefined,
          },
          navigate,
        })
      );
    },
    [selectedPerformanceStatus, dispatch, navigate]
  );

  const handleInterestRateChange = useCallback(
    (min: number | undefined, max: number | undefined) => {
      setInterestRate([min, max]);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            interestRate: { min: min || null, max: max || null },
          },
          navigate,
        })
      );
    },
    [dispatch, navigate]
  );

  const handleCurrentLoanAmountChange = useCallback(
    (min: number | undefined, max: number | undefined) => {
      setCurrentLoanAmount([min, max]);
      dispatch(
        updateFiltersAndFetch({
          filters: {
            currentLoanAmount: { min: min || null, max: max || null },
          },
          navigate,
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <div className="w-full space-y-4 mt-1 mb-4">
      <div className="grid grid-cols-2 gap-4 min-[500px]:grid-cols-4">
        <div className="dropdown cursor-pointer">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray hover:text-black text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Select State(s)
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-50 bg-brown rounded-lg w-72 max-h-60 overflow-y-auto"
            style={{ width: "calc(100vw - 30px)" }}
          >
            {states.map((state: UnitedStatesState, index: number) => (
              <label
                key={`united-states-state-${index}`}
                className="flex items-center pr-4"
              >
                <input
                  type="checkbox"
                  className="checkbox checkbox-xs checked:checkbox-accent"
                  checked={selectedStates.includes(state.value)}
                  onChange={() => handleStateFilter(state.value)}
                />
                <span className="ml-2 select-none cursor-pointer text-very-light-brown">
                  {capitalize(state.name)}
                </span>
              </label>
            ))}
          </ul>
        </div>
        <div className="dropdown cursor-pointer">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray hover:text-black text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Performance Status
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-50 bg-brown rounded-lg max-h-60 overflow-y-auto"
          >
            {Object.values(PerformanceStatusEnum).map((status) => (
              <label key={status} className="flex items-center pr-4">
                <input
                  type="checkbox"
                  className="checkbox checkbox-xs checked:checkbox-accent"
                  checked={selectedPerformanceStatus.includes(status)}
                  onChange={() => handlePerformanceStatusFilter(status)}
                />
                <span className="ml-2 select-none cursor-pointer text-very-light-brown">
                  {status}
                </span>
              </label>
            ))}
          </ul>
        </div>

        <div className="dropdown cursor-pointer">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray hover:text-black text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Targeted ROR
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul className="menu dropdown-content z-50 bg-brown rounded-lg">
            <FilterValueRange
              minValues={rorMinValues}
              maxValues={rorMaxValues}
              state={{ minValue: interestRate[0], maxValue: interestRate[1] }}
              setState={(newState: {
                minValue: number | undefined;
                maxValue: number | undefined;
              }) => {
                handleInterestRateChange(newState.minValue, newState.maxValue);
              }}
            />
          </ul>
        </div>

        <div className="dropdown cursor-pointer">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-outline w-full mb-0.5 font-bold relative hover:bg-white hover:border-secondary text-main-gray hover:text-black text-xs sm:text-sm md:text-lg rounded-[6px]"
          >
            Loan Amount
            <div className="absolute right-2">
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <ul className="menu dropdown-content z-50 bg-brown rounded-lg">
            <FilterValueRange
              minValues={loanAmountMinValues}
              maxValues={loanAmountMaxValues}
              state={{
                minValue: currentLoanAmount[0],
                maxValue: currentLoanAmount[1],
              }}
              setState={(newState: {
                minValue: number | undefined;
                maxValue: number | undefined;
              }) => {
                handleCurrentLoanAmountChange(
                  newState.minValue,
                  newState.maxValue
                );
              }}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterToolbar;

const rorMinValues: Option[] = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 1,
    label: "1%",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 3,
    label: "3%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 6,
    label: "6%",
  },
  {
    value: 7,
    label: "7%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 9,
    label: "9%",
  },
  {
    value: 10,
    label: "10%",
  },
];

const rorMaxValues: Option[] = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 1,
    label: "1%",
  },
  {
    value: 2,
    label: "2%",
  },
  {
    value: 3,
    label: "3%",
  },
  {
    value: 4,
    label: "4%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 6,
    label: "6%",
  },
  {
    value: 7,
    label: "7%",
  },
  {
    value: 8,
    label: "8%",
  },
  {
    value: 9,
    label: "9%",
  },
  {
    value: 10,
    label: "10%",
  },
];

// Add loan amount min and max values
const loanAmountMinValues: Option[] = [
  { value: 0, label: "$0" },
  { value: 50000, label: "$50,000" },
  { value: 100000, label: "$100,000" },
  // ... add more options as needed
];

const loanAmountMaxValues: Option[] = [
  { value: 50000, label: "$50,000" },
  { value: 100000, label: "$100,000" },
  { value: 200000, label: "$200,000" },
  // ... add more options as needed
];
