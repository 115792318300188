import React from "react";

export interface Option {
  label: string;
  value: number;
}

export const RangeOption = ({ label, value }: Option) => {
  return (
    <option className="h-12" value={value}>
      {label}
    </option>
  );
};

interface FilterValueRangeProps {
  minValues: Option[];
  maxValues: Option[];
  state: { minValue: number | undefined; maxValue: number | undefined };
  setState: (newState: {
    minValue: number | undefined;
    maxValue: number | undefined;
  }) => void;
}

const FilterValueRange = ({
  minValues,
  maxValues,
  state,
  setState,
}: FilterValueRangeProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value === "Infinity" ? undefined : Number(value),
    });
  };

  return (
    <>
      <div className="flex w-full columns-1 flex-col bg-brown">
        <div className="flex items-center justify-center gap-1">
          <div className="flex columns-1 flex-col items-start justify-start">
            <label
              style={{
                textTransform: "none",
              }}
              htmlFor="minValue"
              className="py-1 text-xs font-bold  text-zinc-400"
            >
              Minimum
            </label>
            <select
              style={{
                outline: "none",
              }}
              name="minValue"
              value={state.minValue ?? 0}
              className="select select-bordered select-sm w-28  bg-back-light text-text-dark outline-none dark:bg-back-dark dark:text-text-light"
              onChange={handleChange}
            >
              <RangeOption value={0} label={"No Min"} />
              {minValues
                .sort((a, b) => a.value - b.value)
                .filter((item) => item.value < (state.maxValue ?? Infinity))
                .map((item, index) => (
                  <RangeOption
                    key={index}
                    value={item.value}
                    label={item.label}
                  />
                ))}
            </select>
          </div>
          <div className="flex items-center justify-center">
            <p className="mt-3 text-text-dark dark:text-text-light">-</p>
          </div>
          <div className="flex columns-1 flex-col items-start justify-start">
            <label
              style={{
                textTransform: "none",
              }}
              htmlFor="maxValue"
              className="py-1 text-xs font-bold text-zinc-400"
            >
              Maximum
            </label>
            <select
              style={{
                outline: "none",
              }}
              name="maxValue"
              value={state.maxValue ?? Infinity}
              className="select select-bordered select-sm w-28 bg-back-light text-text-dark outline-none dark:bg-back-dark dark:text-text-light"
              onChange={handleChange}
            >
              <RangeOption value={Infinity} label={"No Max"} />
              {maxValues
                .sort((a, b) => a.value - b.value)
                .filter((item) => item.value > (state.minValue ?? 0))
                .map((item, index) => (
                  <RangeOption
                    key={index}
                    value={item.value}
                    label={item.label}
                  />
                ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterValueRange;
